// Используемые в разных компонентах функции

import $ from 'jquery';

const checkPhoneNumber = (arg) => {
  let phoneNumber = arg + '';

  phoneNumber = phoneNumber.replace(/[^\d]/g, '');

  if(phoneNumber.length === 10) {
    phoneNumber = '7' + phoneNumber// eslint-disable-next-line
  } else if(phoneNumber.length === 11 && phoneNumber[0] == '8') {
    phoneNumber = phoneNumber.replace(phoneNumber[0], '7')
  }
  
  return phoneNumber;
};

const checkPassword = (arg) => {
  let password = arg + '';

  password = password.replace(/\s/g, '');
  
  return password;
};

const numWithSep = (number) => {
  if(typeof number !== 'number') {
    return false
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const windowOpen = (url) => {
  const yourWindow = window.open();
  yourWindow.opener = null;
  yourWindow.location = url;
};

const showErrorMessage = (error) => {
  if(!document.getElementById('error-message')) {
    const messageContainer = document.createElement("div");
    const message = document.createElement("div");
    messageContainer.appendChild(message)
    messageContainer.setAttribute('class', 'error-message');
    messageContainer.setAttribute('id', 'error-message');
    message.innerHTML = 'Произошла ошибка, попробуйте позже';
    document.getElementById("wrapper").appendChild(messageContainer);
    setTimeout(() => {
      messageContainer.remove();
    }, 5000)
  }
  if(error !== null && error !== undefined) {
    console.log(error)
  }
};

const setLoadStatus = (action, bool) => action(bool);

const num2str = (n, text_forms, stx) => {  
  if(!n && n !== '' && n !== 0) return n + ' ' + text_forms[2]
  if(typeof n !== 'number') n = +n

  n = Math.abs(n) % 100;
  const n1 = n % 10;
  const func = (num) => stx ? stx[num]+" "+n+" "+text_forms[num]  : n+" "+text_forms[num];
// eslint-disable-next-line 
  return n1 > 1 && n1 < 5 ? func(1) : n1 == 1 ? func(0) : func(2)
};

const addMessage = (selector, messagetext, parent = false, isNotError) => {
  let element = isElement(selector) ? selector : document.querySelector(selector);
  if(parent) element = element.parentElement;

  element.setAttribute('messagetext', messagetext);
  element.classList.add(!isNotError ? 'has-error' : 'has-message');

  setTimeout(removeMessage, 10000, selector)
};

const removeMessage = (selector) => {
  let element = isElement(selector) ? selector : document.querySelector(selector);
  if(!element) return false;
  element.setAttribute('messagetext', '');

  const remover = (className) => element.classList.contains(className) ? element.classList.remove(className) : null;
  remover('has-message')
  remover('has-error')
};

const isEquivalent = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);
  if (aProps.length !== bProps.length) return false;

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    if (a[propName] !== b[propName]) return false;
  }

  return true;
}  

const isElement = (o) => {
  return (
    typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
    o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
  );
}

const blurOn = () => {
  $(document).ready(() => {
    document.querySelector('.main-page-wrapper').classList.add('filter')
    document.querySelector('body').classList.add('overflow-x')
  });
};

const blurOff = () => {
  if(document.querySelector('.main-page-wrapper')) {
    if(document.querySelector('.main-page-wrapper').classList.contains('filter')) {
      document.querySelector('.main-page-wrapper').classList.remove('filter')
    }
  }

  if(document.querySelector('body').classList.contains('overflow-x')) {
    document.querySelector('body').classList.remove('overflow-x')
  }
}

const delHyphen = (num) => {
  if(num.indexOf('-') + 1 || num.indexOf('−') + 1) {
    return num.replace('-', "").replace('−', "")      
  }
  return num
};

const createCurrencySymbol = (cur) => {
  let sym;
  switch (cur) {
    case 'RUB': return sym = '₽';
  
    case 'USD': return sym = '$';

    case 'UAH': return sym = '₴';

    case 'EUR': return sym = '€';
// eslint-disable-next-line
    default: return sym = cur
  }
};

const curInTheRightPlace = (cur, balance, isNegatively, balanceWithSeparate) => {
  let result;

  if(balanceWithSeparate) {
    balance = numWithSep(balance)
  }

  if(cur === "USD" || cur === "EUR") {
    result = createCurrencySymbol(cur) + "" + balance
  } else {
    result = balance + " " + createCurrencySymbol(cur);
  }
  
  if(isNegatively) {
    if(isNegatively === 1) {
      result = '− ' + delHyphen(result)
    } else if(isNegatively === 2) {
      result = '+ ' + result
    }
  }

  return result
};

const separateType = (array ,separators = []) => {
  let newArray = [];
  array.forEach((element, i) => {
    separators.forEach((sep) => {
      if(element.paymentType === sep) {
        newArray[newArray.length] = array[i]
      }
    })
  });
  return newArray
};

const convertMonth = (date = '') => {
  const months = [
    'января',
    'феараля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const a = date.split('.');
  const newDate = a[0] + ' ' + months[+a[1]] + ' ' + a[2];
  if(a.length !== 3 || !a[0] || !months[+a[1]] || !a[2]) {
    return date
  }
  return newDate
};

const appendScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
}

const removeScript = (url) => {
  let allsuspects=document.getElementsByTagName("script");
  for (let i=allsuspects.length; i>=0; i--){
  if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null 
      && allsuspects[i].getAttribute("src").indexOf(`${url}`) !== -1){
      allsuspects[i].parentNode.removeChild(allsuspects[i])
    }    
  }
}

const convertObjectToOption = (array, value, label) => {
  let newArray = [];
  array.forEach((element, i) => {
    newArray[i] = element;
    newArray[i].value = element[value];
    newArray[i].label = element[label]
  });

  return newArray;
};


const setRootHeight = (selector) => {
  if($('#root').outerHeight() < $(selector).outerHeight() + 180 && $(window).outerWidth() > 1024) {
    $('#root').outerHeight($(selector).outerHeight() + 180)
  }
  if($(window).outerWidth() <= 1024) {
    $('#root').outerHeight($(selector).outerHeight())
  }
}

const unsetRootHeight = () => {
  $('#root').css('height', '');
};

const scrollPageTo = (element) => {
  element.scrollIntoView({block: "center", behavior: "smooth"});
};

const bodyOnMouseDown = (func, selector) => {
  function handler(event) {
    if(!$(event.target).closest(selector).length && !$(event.target).is(selector)) {
      func();
      event.stopImmediatePropagation();
    }
  }; 
  $('body').on('mousedown', handler);
};

const bodyOffBindedFuncs = (func = () => {}) => {
  $('body').off('mousedown');
  $('body').off('keydown');
  func();
};

const bodyOnEscEnter = (func) => {
  function handler(event) {
    if(+event.keyCode === 27) {
      func();
      event.stopImmediatePropagation();
    }
  }; 
  $('body').on('keydown', handler);
};

const transliterate = (text) => text = text
  .replace(/\u0401/g, 'YO')
  .replace(/\u0419/g, 'I')
  .replace(/\u0426/g, 'TS')
  .replace(/\u0423/g, 'U')
  .replace(/\u041A/g, 'K')
  .replace(/\u0415/g, 'E')
  .replace(/\u041D/g, 'N')
  .replace(/\u0413/g, 'G')
  .replace(/\u0428/g, 'SH')
  .replace(/\u0429/g, 'SCH')
  .replace(/\u0417/g, 'Z')
  .replace(/\u0425/g, 'H')
  .replace(/\u042A/g, '')
  .replace(/\u0451/g, 'yo')
  .replace(/\u0439/g, 'i')
  .replace(/\u0446/g, 'ts')
  .replace(/\u0443/g, 'u')
  .replace(/\u043A/g, 'k')
  .replace(/\u0435/g, 'e')
  .replace(/\u043D/g, 'n')
  .replace(/\u0433/g, 'g')
  .replace(/\u0448/g, 'sh')
  .replace(/\u0449/g, 'sch')
  .replace(/\u0437/g, 'z')
  .replace(/\u0445/g, 'h')
  .replace(/\u044A/g, "'")
  .replace(/\u0424/g, 'F')
  .replace(/\u042B/g, 'I')
  .replace(/\u0412/g, 'V')
  .replace(/\u0410/g, 'A')
  .replace(/\u041F/g, 'P')
  .replace(/\u0420/g, 'R')
  .replace(/\u041E/g, 'O')
  .replace(/\u041B/g, 'L')
  .replace(/\u0414/g, 'D')
  .replace(/\u0416/g, 'ZH')
  .replace(/\u042D/g, 'E')
  .replace(/\u0444/g, 'f')
  .replace(/\u044B/g, 'i')
  .replace(/\u0432/g, 'v')
  .replace(/\u0430/g, 'a')
  .replace(/\u043F/g, 'p')
  .replace(/\u0440/g, 'r')
  .replace(/\u043E/g, 'o')
  .replace(/\u043B/g, 'l')
  .replace(/\u0434/g, 'd')
  .replace(/\u0436/g, 'zh')
  .replace(/\u044D/g, 'e')
  .replace(/\u042F/g, 'Ya')
  .replace(/\u0427/g, 'CH')
  .replace(/\u0421/g, 'S')
  .replace(/\u041C/g, 'M')
  .replace(/\u0418/g, 'I')
  .replace(/\u0422/g, 'T')
  .replace(/\u042C/g, "'")
  .replace(/\u0411/g, 'B')
  .replace(/\u042E/g, 'YU')
  .replace(/\u044F/g, 'ya')
  .replace(/\u0447/g, 'ch')
  .replace(/\u0441/g, 's')
  .replace(/\u043C/g, 'm')
  .replace(/\u0438/g, 'i')
  .replace(/\u0442/g, 't')
  .replace(/\u044C/g, "'")
  .replace(/\u0431/g, 'b')
  .replace(/\u044E/g, 'yu')
;

const getFromBase64 = (imageBase64, filename, type, namesSet) =>  {
  const bstr = atob(imageBase64);
  let n = bstr.length,
      u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let file = new File([u8arr], namesSet[filename] + '.', {type: type})

  file = Object.assign(file, {
    preview: URL.createObjectURL(file),
    altName: filename
  });

  return file
};

const postBase64 = (file, name, onLoad = () => {}) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file[0]);
  reader.onload = async () => {
    if(onLoad) await onLoad(file, name)
    return resolve(
      reader.result.replace(/^data:image.+;base64,/, '').replace(/^data:application.pdf;base64,/, '')
    )
  };
  reader.onerror = error => reject(error);
});

const phoneNumberCutter = (phoneNumber = '') => {
  let wordArray = phoneNumber.split('')
  let firstChar = wordArray[0]
  if(firstChar === '+') firstChar = ''
  else if(firstChar === '8') firstChar = '7'
  else if(firstChar === '7') firstChar = '7'
  wordArray[0] = firstChar
  const cuttedPhoneNumber = wordArray.join('')
  return cuttedPhoneNumber
};

export {
  checkPhoneNumber,
  checkPassword,
  numWithSep,
  showErrorMessage,
  setLoadStatus,
  num2str,
  addMessage,
  removeMessage,
  blurOn,
  blurOff,
  createCurrencySymbol,
  curInTheRightPlace,
  delHyphen,
  separateType,
  convertMonth,
  appendScript,
  removeScript,
  convertObjectToOption,
  unsetRootHeight,
  setRootHeight,
  windowOpen,
  transliterate,
  scrollPageTo,
  isEquivalent,
  isElement,
  bodyOnEscEnter,
  bodyOffBindedFuncs,
  bodyOnMouseDown,
  postBase64,
  getFromBase64,
  phoneNumberCutter
}