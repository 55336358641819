import React from "react";
import { Router } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from 'react-redux';

import ReactDOM from "react-dom";
import reducer from './reducers/reducer';
import App from "./app/app.jsx";
import history from "./scripts/history";

export const store = createStore(reducer);

ReactDOM.render((
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>
	), document.getElementById("inner"));