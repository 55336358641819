const initialState = {
  auth: false,
  doneStep: 0,
  initialWrapperColor: '#F2F2F2',
  wrapperColor: '#F2F2F2',
  popup: false,
  flightData: false,
  clientTourists: false,
  load: false,
  modalWindow: {open: false},
  canEdit: true,
};

const reducer = (state = initialState, action) => {
  
  switch(action.type) {
    case 'AUTH': 
      return state = {
        ...state,
        auth:  action.payload
      }

    case 'CAN_EDIT': 
      return state = {
        ...state,
        canEdit:  action.payload
      }
    
    case 'STEP_CHANGE': 
      return state = {
        ...state,
        doneStep:  action.payload
      }
    
    case 'WRAPPER_COLOR': 
      return state = {
        ...state,
        wrapperColor:  action.payload
      }

    case 'POPUP_IS_OPEN': 
      return state = {
        ...state,
        popup:  action.payload
      }

    case 'FLIGHT_DATA_SHOW': 
      return state = {
        ...state,
        flightData:  !state.flightData
      }

    case 'CLIENT_TOURISTS_SHOW': 
      return state = {
        ...state,
        clientTourists:  !state.clientTourists
      }
    
    case 'LOAD_CHANGE': 
      return state = {
        ...state,
        load:  action.payload
      }
    
    case 'MODAL_WINDOW': 
      return state = {
        ...state,
        modalWindow:  action.payload
      }

    default: return state
  }
}

export default reducer;