// Главный компонент приложения

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { ValidFuncProvider } from '../scripts/validFuncContext';
import ValidFuncService from "../services/validFuncService";
import LoadSpinner from "../utils/spinner";
import RegStepsContainer from "../components/reg-login/registration-steps/reg-steps-container.jsx";
import SignUp from '../components/reg-login/sign-up/sign-up'
import "./app.scss";
import DonePage from "../components/reg-login/registration-steps/done-page";
import EmailConfirm from '../components/reg-login/registration-steps/email-confirm';

const App = (props) => {
  const validFunc = new ValidFuncService();
  const spinner = props.load ? <LoadSpinner /> : null;

  return (
    <ValidFuncProvider value={validFunc}>
      {spinner}
      <Switch>
        <Route path={"/registration/"} component={RegStepsContainer} />
        <Route path={"/pre-registration"} component={SignUp} />
        <Route path={"/done"} component={DonePage} />
        <Route path={`/email-confirm`} component={EmailConfirm} />

        <Redirect to={"/pre-registration"} />
      </Switch>
    </ValidFuncProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    load: state.load
  }
};

export default connect(mapStateToProps)(App);