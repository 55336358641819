// компонент первого шага расширенной регистрации

import React, { Component } from "react";
import { connect } from "react-redux";

import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import ApiService from "../../../services/apiService";
import MyInput from "../../../utils/input";
import history from "../../../scripts/history";
import { addMessage, isEquivalent, setLoadStatus } from "../../../scripts/functions";

class RegStepFirst extends Component {

  state = {
    changed: false,
    inn: '',
    innIsCorrect: false,
    institutionType: 'Freelance',
    agree: true,
    // legalAddress: {},
    companyPhone: '',
    companyName: '',
    ceo_name: '',
    ceo_post: '',
    zipCode: '',
    city: '',
    street: '',
    toCreate: true,
  };

  stateCopy = {};

  apiService = new ApiService();
  _mounted = false

  async componentDidMount() {
    this._mounted = true
    await setLoadStatus(this.props.load, true);
    this.props.updateCurStep(0);
    const companyId = localStorage.getItem('companyId');
    if(companyId) await this.getData(companyId);
    await setLoadStatus(this.props.load, false);
  };

  componentWillUnmount() {
    this._mounted = false
  }

  checkInn = async (checkValidity) => {
    if(checkValidity && checkValidity('reg-step-first-form')) return false
    await setLoadStatus(this.props.load, true);
    return await this.apiService.getResource('api/companyInfo/getCompanyInfo', 'POST', {inn: this.state.inn.replaceAll(' ', '')})
    .then(async (res) => {
      await setLoadStatus(this.props.load, false);
      if(!this._mounted) return false
      const {data} = res;

      if(res.success) {
        await this.setState({
          institutionType: 'Freelance',
          innIsCorrect: data.inn,
          // legalAddress: {
          //   city: data.city,
          //   zipCode: data.postal_code,
          //   street: data.street,
          // },
          companyName: data.name,
          ceo_name: data.ceo_name,
          ceo_post: data.ceo_post,
        })
      }
      else addMessage('#inn', res.message, true)

      return true
    })
    .catch((error) => console.log(error))
  };

  getData = async (companyId) => {
    await setLoadStatus(this.props.load, true);
    return await this.apiService.getResource(`api/companyInfo?companyId=${companyId}`, "GET", {})
    .then(async (res) => {
      await setLoadStatus(this.props.load, true); 

      const gettedData = {
        // zipCode: res.data.address.legal.zipCode,
        // city: res.data.address.legal.city,
        // street: res.data.address.legal.street,
        toCreate: false,
        innIsCorrect: res.data.inn || '',
        inn: res.data.inn || '',
        companyPhone: res.data.phoneNumber || '',
        agree: isEquivalent(res.data.address.legal, res.data.address.actual),
      }
      
      if(res && res.success) {
        this.stateCopy = gettedData;
        await this.setState({...gettedData})
      }

      return false;
    })
  };
  
  onSubmit = (e) => {
    e.preventDefault();
  };

  toTheNextStep = async (checkValidity) => {
    const {state} = this;
    if(checkValidity("reg-step-first-form")) {
      return false
    }

    if(!state.changed) {
      this.nextStep()
      return false
    }

    await this.apiService.getResource('api/companyInfo', state.toCreate ? 'POST' : 'PUT', {
      type: 'Freelance',
      companyId: localStorage.getItem('companyId'), 
      inn: state.inn, 
      phoneNumber: state.toCreate ? localStorage.getItem('phoneNumber') : state.companyPhone
    })
    .then(async (res) => {
      if(!res.success) {
        return false;
      }

      localStorage.setItem('companyId', res.data.companyId)
      this.nextStep()
    })
  }

  nextStep = () => {
    this.props.updateDoneStep(1);
    history.push(`/registration/step-2`);
  }

  render() {
    const { innIsCorrect, inn } = this.state;

    return (
      <ValidFuncConsumer>
        {({checkValidity}) => 
          <form noValidate id="reg-step-first-form" className="reg-step-form" onSubmit={this.onSubmit}>

            <div className="row-of-inputs without-media">
              <MyInput disabled={!this.props.state.canEdit} title="Введите ИНН" id="inn" value={inn} onChange={(e) => this.setState({inn: e.target.value, changed: true})} required />
              {this.props.state.canEdit && innIsCorrect ? <button type="button" className="primary-text return-to-initial" onClick={() => this.setState({innIsCorrect: false, inn: '', changed: true})}>Изменить</button> : null}
            </div>

            <div className="btns-container">
              <button type="button" id="app-btn" className="primary-btn app-btn" onClick={() => 
                this.props.state.canEdit ? 
                  this.toTheNextStep(checkValidity)
                : this.nextStep()
              }>Продолжить</button> 
            </div>
          </form>
        }
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({type: 'LOAD_CHANGE', payload: bool})
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepFirst);