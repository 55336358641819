import React, { Component } from "react";
import Media from "react-media";

import { Link } from "react-router-dom";
import history from "../../../scripts/history";
import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import SelfEncashmentImg from './self-encashment'
import Select from "react-select";
import customStylesSelect from '../../../scripts/customStylesSelect';
import ApiService from "../../../services/apiService";
import CheckboxList from "../../../utils/checkbox";
import { convertObjectToOption, setLoadStatus } from "../../../scripts/functions";
import { connect } from "react-redux";
import sberLogo from '../../../img/sberbank-logo.svg'
import alfaLogo from '../../../img/alfa-bank-logo.svg'

class RegStepFourth extends Component {

  state = {
    type: [false, false],
    options: [],
    selectedRecipient: { label: 'Выберите получателя' },
    toCreate: true,
    cards: [],
  }

  options = ['AlfaBank', 'Sberbank']

  apiService = new ApiService();

  async componentDidMount() {
    await setLoadStatus(this.props.load, true);
    this.props.updateCurStep(2);
    await this.getEmployees();
    await setLoadStatus(this.props.load, false);
    await this.getAdditionalCards();
  };

  getAdditionalCards = () => {
    return this.apiService.getResource('api/selfEncashmentCard/cardTypesInfo', 'GET', true)
      .then((res) => {
        let textCard = [];
        let descriptionCard = [];
        try {
          let cardInfo = res.data;
          for (let value of Object.values(cardInfo)) {
            textCard.push(value.name);
            descriptionCard.push(value.description);
          }
          this.setState({ textCard, descriptionCard });
        } catch (err) { console.log('error', err); }
      })
  };

  getEmployees = async () => {
    return await this.apiService.getResource('api/employee/getEmployees', 'POST', { companyId: localStorage.getItem('companyId') })
      .then(async (res) => {
        if (res && res.success) {
          const options = convertObjectToOption(res.data, 'userId', 'name');
          const ceo = options.filter(o => o.role === 'CEO');
          await this.setState({ options: options, selectedRecipient: ceo.length ? ceo[0] : { label: 'Выберите получателя' } })
        }
        await this.getCardsList()
        return true
      })
  };

  getCardsList = async () => {
    return await this.apiService.getResource(`api/selfEncashmentCard/getByCompanyId?companyId=${localStorage.getItem('companyId')}`, "GET", {})
      .then((res) => {
        if (!res || !res.data || !res.data.length) return false
        this.setState({
          toCreate: false,
          cards: res.data.filter(d => d.isActive) || [],
        })
      })
  };

  toTheNextStep = async () => {
    const { type, selectedRecipient: { value } } = this.state;
    const cards = type.map((t, index) => t ? ({ type: this.options[index], userId: value }) : false).filter(t => t)

    setLoadStatus(this.props.load, true);

    const request = cards.some(c => c) ?
      cards.length === 1 ? this.apiService.getResource(`api/selfEncashmentCard`, "POST", cards[0]) :
        this.apiService.getResource(`api/selfEncashmentCard/AddSelfEncashmentCard`, "POST", cards) :
      this.apiService.getResource('api/registration/skipStage', 'POST', {
        companyId: localStorage.getItem('companyId'),
        stage: 5
      });

    request
      .then((res) => {
        this.nextStep()
        return false
      })
    setLoadStatus(this.props.load, false);
  };

  handleSelect = (selectedRecipient) => this.setState({ selectedRecipient })

  checkboxHandler = (typeIndex) => {
    const { type } = this.state;
    const value = type[typeIndex];

    type.splice(typeIndex, 1, !value)
    this.setState({ type })
  }

  nextStep = () => {
    this.props.updateDoneStep(3);
    history.push('/done')
  }

  render() {
    const { type, cards, textCard, descriptionCard } = this.state;
    const hasCards = cards.length;

    return (
      <ValidFuncConsumer>
        {({ checkValidity }) =>
          <form id="reg-step-fourth-form" className="reg-step-form">
            <Media query="(max-width: 1024px)" render={() => (
              <h2>Самоинкассация</h2>
            )} />
            <div className="self-encashment">
              <div>
                <h2>Самоинкассация</h2>
                <div className="self-encashment-text">
                  Моментальный способ оплаты туров наличными в 100.000+ банкоматов 10 крупнейших банков: СБЕР, Альфа-Банка и банки-партнеры в 83 регионах РФ.
                  Это удобно - вам будет выпущена виртуальная карта в режиме онлайн.
                  Без ограничений по сумме платежа. Онлайн-чек на полную стоимость тура.
                </div>
              </div>

              <Media query="(min-width: 1025px)" render={() => (
                <SelfEncashmentImg />
              )} />
            </div>

            {hasCards ? <>
              <h3>ваши карты самоинкассации</h3>
              <div className="card-cards">
                {cards.map((r) => {
                  const src = r.type === 'Sberbank' ? sberLogo : r.type === 'AlfaBank' ? alfaLogo : '';
                  return (
                    <div className={`card ${r.type}`} key={r.id}>
                      <div>**{r.cardNumber}</div>
                      {src ? <img alt="" src={src} /> : null}
                    </div>
                  )
                }
                )}
              </div>
              <h3>заказать дополнительные карты</h3>
            </> : null}

            <CheckboxList isCheckbox className="self-encashment-types" multiple count={type} text={textCard} description={descriptionCard} handler={this.checkboxHandler} />

            {type[0] || type[1] ? <div className="app-label">
              <div className="input-title">Получатель карты</div>
              <Select
                options={this.state.options}
                value={this.state.selectedRecipient}
                onChange={this.handleSelect}
                styles={customStylesSelect}
                className="my-select"
                classNamePrefix="my-select"
                placeholder={<div>Поиск...</div>}
                noOptionsMessage={() => 'Ничего не найдено'}
              />
            </div> : null}

            <div className="btns-container">
              <Link to="/registration/step-3" className="secondary-btn app-btn">Назад</Link>
              <button type='button' id="app-btn" className="primary-btn app-btn" onClick={() =>
                this.props.state.canEdit ?
                  this.toTheNextStep(checkValidity)
                  : this.nextStep()
              }>{!type[0] && !type[1] ? 'Пропустить' : 'Продолжить'}</button>
            </div>
          </form>
        }
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepFourth);