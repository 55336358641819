// Общий компонент шагов расширенной регистрации

import React, {Component} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ApiService from "../../../services/apiService";
import RegStepFirst from "./reg-step-1";
import RegStepSecond from "./reg-step-2";
import history from "../../../scripts/history";
import RegStepFourth from "./reg-step-4";

class RegStepsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      curStep: 0,
      loaded: false
    }
  }

  apiService = new ApiService();

  async componentDidMount() {
    if(localStorage.getItem('companyId')) await this.checkRegistration()

    this.setState({loaded: true})
  };

  componentWillUnmount() {
    this.props.wrapperColorChanger(this.props.state.initialWrapperColor)
  };

  checkRegistration = async () => {
    return await this.apiService.getResource('api/registration/checkRegistrationStage', 'POST', {
      companyId: localStorage.getItem('companyId')
    })
    .then(async (res) => {
      const currentStep = this.props.location.pathname.slice(-1);

      if(res.stage + 1 < currentStep) {
        history.push(`/registration/step-${res.stage + 1}`)
      }

      await this.props.curStepChanger(res.stage);
      await this.props.canEditChanger(res.canEdit);
      return true
    })
  };
  
  updateCurStep = (update) => {
    this.setState({curStep:update})
  };
  
  updateDoneStep = async (update) => {
    if(this.props.state.doneStep < update) {
      await this.props.curStepChanger(update);
    }
  };

  listItemClick = (num) => {
    const {curStep} = this.state;
    --num
    if(curStep !== num && num <= this.props.state.doneStep) {
      history.push(`/registration/step-${num + 1}`);
      return false
    }
    if(curStep + 1 === num) {
      document.querySelector('#app-btn').click();
      return false
    }
  };

  render() {
    const {curStep, loaded} = this.state;
    const doneStep = this.props.state.doneStep;
    const listItemConstructor = (text, count) => {
      return <li num={count} className={curStep + 1 === count ? "steps-list-active-step" : doneStep + 1 > count ? "steps-list-past-step" : ''} onClick={() => this.listItemClick(count)}>
        {text}
      </li>
    }

    return (
      <div className="reg-steps-container">
        <h1>
          Регистрация учетной записи
        </h1>

        <ul className="steps-list">
          {listItemConstructor('Фрилансер', 1)}
          {listItemConstructor('Документы', 2)}
          {listItemConstructor('Способ внесения средств', 3)}

          <svg className="steps-list-line">
            <line x1="0%" y1="50%" x2="100%" y2="50%"stroke="#E0E0E0" strokeWidth="1"/>
          </svg>
        </ul>

        <div className="current-reg-step">
          {loaded ? <Switch>
            <Route path={`/registration/step-1`} render={(props) => <RegStepFirst updateDoneStep={this.updateDoneStep}  updateCurStep={this.updateCurStep} {...props}/> }/>
            <Route path={`/registration/step-2`} render={(props) => <RegStepSecond updateDoneStep={this.updateDoneStep} updateCurStep={this.updateCurStep} {...props}/> }/>
            <Route path={`/registration/step-3`} render={(props) => <RegStepFourth updateDoneStep={this.updateDoneStep} updateCurStep={this.updateCurStep} {...props}/> }/>
            <Redirect to="/registration/step-1"/>
          </Switch> : null}
        </div>
      </div>
    )
  }
};

const mapState = (state) => {
  return {
    state: state
  }
}

const mapDispatch = (dispatch) => {
  return {
    curStepChanger: (number) => {
      return dispatch({type: 'STEP_CHANGE', payload: number})
    },
    wrapperColorChanger: (bool) => dispatch({type: 'WRAPPER_COLOR', payload: bool}),
    canEditChanger: (bool) => dispatch({type: 'CAN_EDIT', payload: bool})
  }
}

export default connect(mapState, mapDispatch)(RegStepsContainer);