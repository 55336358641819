// Компонент входа/первичной регистрации

import React from "react";
import MainRegImg from "./main-reg-img.js";

const SidePanel = () => {
  return (
    <div className="side-panel">
      <div className="side-panel-middle">
        <div className="side-panel-top-text">
          «Турагентство в кармане» — туры, отели, круизы, авиабилеты, страховки, онлайн кредитование и удобные платежи в твоем смартфоне.
        </div>

        <MainRegImg />

        <div className="side-panel-bottom-text">Уже более 1000 специалистов выбрали freelance.travel для работы с туристами.</div>
      </div>
    </div>
  )
};

export default SidePanel;