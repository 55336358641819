import React from "react";

const SelfEncashmentImg = () => {
  return (
    <svg className="self-encashment-img" width="123" height="166" viewBox="0 0 123 166" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M35.6318 135.768H116.36V4.98648H35.6318V135.768Z" fill="url(#paint0_linear)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M112.722 0.650665L67.2075 1.00293V6.91282H90.2579L106.688 6.97011C112.224 6.97011 116.711 11.4581 116.711 16.9935V122.342C116.711 127.878 112.224 132.365 106.688 132.365L67.2075 132.526V144.937L112.722 145.272C118.258 145.272 122.746 140.785 122.746 135.249V10.6732C122.746 5.1378 118.258 0.650665 112.722 0.650665Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M38.2962 122.342V16.9943C38.2962 11.4572 42.7808 6.97267 48.3187 6.97267H64.7505L65.4798 9.29234C65.827 10.7442 67.1257 11.771 68.6186 11.771H82.4126C83.9046 11.771 85.2043 10.7442 85.5514 9.29234L86.2807 6.97267H102.712C108.25 6.97267 112.74 11.4572 112.74 16.9943V122.342C112.74 127.879 108.25 132.364 102.712 132.364H48.3187C42.7808 132.364 38.2962 127.879 38.2962 122.342ZM109.441 0.671187C109.213 0.654941 108.98 0.648956 108.746 0.648956H42.2849C36.7469 0.648956 32.2632 5.13866 32.2632 10.6766V135.246C32.2632 140.784 36.7469 145.274 42.2849 145.274H108.746C108.974 145.274 109.202 145.268 109.429 145.251C114.649 144.898 118.774 140.556 118.774 135.246V10.6766C118.774 5.37208 114.655 1.03029 109.441 0.671187Z" fill="#EFECEA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M106.484 11.6787V18.0546C106.484 18.4188 106.187 18.7155 105.818 18.7155C105.453 18.7155 105.157 18.4188 105.157 18.0546V11.6787C105.157 11.3136 105.453 11.0186 105.818 11.0186C106.187 11.0186 106.484 11.3136 106.484 11.6787Z" fill="#1C4158"/>
<path fillRule="evenodd" clipRule="evenodd" d="M103.955 13.1211V18.0546C103.955 18.4188 103.658 18.7155 103.294 18.7155C102.929 18.7155 102.633 18.4188 102.633 18.0546V13.1211C102.633 12.756 102.929 12.4594 103.294 12.4594C103.658 12.4594 103.955 12.756 103.955 13.1211Z" fill="#1C4158"/>
<path fillRule="evenodd" clipRule="evenodd" d="M101.425 14.567V18.0546C101.425 18.4188 101.128 18.7155 100.764 18.7155C100.4 18.7155 100.103 18.4188 100.103 18.0546V14.567C100.103 14.2027 100.4 13.9077 100.764 13.9077C101.128 13.9077 101.425 14.2027 101.425 14.567Z" fill="#1C4158"/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.9009 16.3736V18.0546C98.9009 18.4188 98.6051 18.7155 98.24 18.7155C97.8758 18.7155 97.5791 18.4188 97.5791 18.0546V16.3736C97.5791 16.0094 97.8758 15.7127 98.24 15.7127C98.6051 15.7127 98.9009 16.0094 98.9009 16.3736Z" fill="#1C4158"/>
<path fillRule="evenodd" clipRule="evenodd" d="M71.4194 6.97011C71.4194 6.21769 70.8089 5.60721 70.0565 5.60721C69.3032 5.60721 68.6919 6.21769 68.6919 6.97011C68.6919 7.72338 69.3032 8.33472 70.0565 8.33472C70.8089 8.33472 71.4194 7.72338 71.4194 6.97011Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M80.9754 8.33472H74.7671C74.0138 8.33472 73.4033 7.72338 73.4033 6.97011C73.4033 6.21769 74.0138 5.60721 74.7671 5.60721H80.9754C81.7287 5.60721 82.3392 6.21769 82.3392 6.97011C82.3392 7.72338 81.7287 8.33472 80.9754 8.33472Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.6675 53.1445H103.294V39.607H46.6675V53.1445Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.6675 53.1445H103.294V48.4855H46.6675V53.1445Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.6675 53.1445H50.6879V39.607H46.6675V53.1445Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.4779 120.643H53.8488C49.8824 120.643 46.6675 117.428 46.6675 113.462V82.2271C46.6675 78.2607 49.8824 75.0458 53.8488 75.0458H97.4779C101.444 75.0458 104.659 78.2607 104.659 82.2271V113.462C104.659 117.428 101.444 120.643 97.4779 120.643Z" fill="#7DA9CE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M87.8301 97.8449C87.8301 91.1253 82.3827 85.6788 75.6631 85.6788C68.9444 85.6788 63.4971 91.1253 63.4971 97.8449C63.4971 104.564 68.9444 110.011 75.6631 110.011C82.3827 110.011 87.8301 104.564 87.8301 97.8449Z" fill="#B7DEEB"/>
<path fillRule="evenodd" clipRule="evenodd" d="M75.663 90.6209C75.3116 90.6209 75.0269 90.3353 75.0269 89.9839V86.7493C75.0269 86.3979 75.3116 86.1123 75.663 86.1123C76.0144 86.1123 76.3 86.3979 76.3 86.7493V89.9839C76.3 90.3353 76.0144 90.6209 75.663 90.6209Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M71.7333 91.6742C71.5135 91.6742 71.2998 91.5597 71.1818 91.3553L69.564 88.5543C69.3888 88.2507 69.4931 87.8609 69.7975 87.6856C70.1027 87.5086 70.4918 87.6129 70.667 87.9181L72.2848 90.7192C72.4601 91.0227 72.3557 91.4126 72.0513 91.5879C71.9505 91.6469 71.841 91.6742 71.7333 91.6742Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M68.8556 94.5514C68.747 94.5514 68.6375 94.524 68.5375 94.465L65.7364 92.849C65.432 92.6738 65.3277 92.2847 65.503 91.9795C65.68 91.6751 66.069 91.5691 66.3726 91.7469L69.1736 93.3629C69.4789 93.5382 69.5832 93.9272 69.4071 94.2324C69.2891 94.4368 69.0753 94.5514 68.8556 94.5514Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M67.8028 98.4819H64.5683C64.2168 98.4819 63.9321 98.1963 63.9321 97.8449C63.9321 97.4927 64.2168 97.2071 64.5683 97.2071H67.8028C68.1542 97.2071 68.4389 97.4927 68.4389 97.8449C68.4389 98.1963 68.1542 98.4819 67.8028 98.4819Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M66.0554 104.03C65.8356 104.03 65.6219 103.916 65.503 103.711C65.3277 103.407 65.432 103.017 65.7364 102.842L68.5375 101.225C68.8427 101.048 69.2317 101.152 69.407 101.457C69.5832 101.762 69.4788 102.152 69.1745 102.327L66.3734 103.944C66.2725 104.003 66.1631 104.03 66.0554 104.03Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M70.1155 108.091C70.007 108.091 69.8984 108.063 69.7975 108.004C69.4931 107.829 69.3888 107.44 69.564 107.136L71.1818 104.334C71.3579 104.029 71.7478 103.923 72.0513 104.101C72.3557 104.276 72.4601 104.665 72.2848 104.971L70.667 107.772C70.549 107.976 70.3353 108.091 70.1155 108.091Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M75.663 109.577C75.3116 109.577 75.0269 109.291 75.0269 108.94V105.705C75.0269 105.354 75.3116 105.068 75.663 105.068C76.0144 105.068 76.3 105.354 76.3 105.705V108.94C76.3 109.291 76.0144 109.577 75.663 109.577Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M81.2112 108.091C80.9915 108.091 80.7777 107.976 80.6589 107.772L79.0421 104.971C78.8668 104.665 78.9711 104.276 79.2755 104.101C79.5799 103.923 79.9689 104.029 80.145 104.334L81.7627 107.136C81.938 107.44 81.8336 107.829 81.5293 108.004C81.4284 108.063 81.3189 108.091 81.2112 108.091Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.2717 104.03C85.1631 104.03 85.0536 104.003 84.9527 103.944L82.1525 102.327C81.8481 102.152 81.7437 101.762 81.919 101.457C82.096 101.152 82.4842 101.048 82.7895 101.225L85.5906 102.842C85.895 103.017 85.9993 103.407 85.8232 103.711C85.7052 103.916 85.4914 104.03 85.2717 104.03Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M86.7587 98.4819H83.5242C83.1728 98.4819 82.8872 98.1963 82.8872 97.8449C82.8872 97.4927 83.1728 97.2071 83.5242 97.2071H86.7587C87.1102 97.2071 87.3949 97.4927 87.3949 97.8449C87.3949 98.1963 87.1102 98.4819 86.7587 98.4819Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M82.4714 94.5514C82.2508 94.5514 82.037 94.4368 81.919 94.2324C81.7437 93.9272 81.8481 93.5382 82.1525 93.3629L84.9536 91.7469C85.2571 91.5691 85.647 91.6751 85.8232 91.9795C85.9993 92.2847 85.895 92.6738 85.5898 92.849L82.7886 94.465C82.6886 94.524 82.5791 94.5514 82.4714 94.5514Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M79.5935 91.6742C79.485 91.6742 79.3764 91.6469 79.2755 91.5879C78.9711 91.4126 78.8668 91.0227 79.0421 90.7192L80.6589 87.9181C80.8359 87.6129 81.2249 87.5086 81.5293 87.6856C81.8336 87.8609 81.938 88.2507 81.7627 88.5543L80.145 91.3553C80.027 91.5597 79.8133 91.6742 79.5935 91.6742Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M84.7972 97.8449C84.7972 92.8003 80.7085 88.7108 75.6631 88.7108C70.6185 88.7108 66.5298 92.8003 66.5298 97.8449C66.5298 102.889 70.6185 106.979 75.6631 106.979C80.7085 106.979 84.7972 102.889 84.7972 97.8449Z" fill="#7DA9CE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M75.6628 91.3553C72.0846 91.3553 69.1741 94.2667 69.1741 97.8449C69.1741 101.422 72.0846 104.334 75.6628 104.334C79.241 104.334 82.1523 101.422 82.1523 97.8449C82.1523 94.2667 79.241 91.3553 75.6628 91.3553ZM75.6628 104.971C71.734 104.971 68.5371 101.774 68.5371 97.8449C68.5371 93.9152 71.734 90.7192 75.6628 90.7192C79.5924 90.7192 82.7893 93.9152 82.7893 97.8449C82.7893 101.774 79.5924 104.971 75.6628 104.971Z" fill="#B7DEEB"/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.9172 89.5786C45.9442 89.5786 45.1567 88.7911 45.1567 87.8172V84.7016C45.1567 83.7303 45.9442 82.9411 46.9172 82.9411C47.8894 82.9411 48.6777 83.7303 48.6777 84.7016V87.8172C48.6777 88.7911 47.8894 89.5786 46.9172 89.5786Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.9172 112.101C45.9442 112.101 45.1567 111.313 45.1567 110.341V107.225C45.1567 106.253 45.9442 105.465 46.9172 105.465C47.8894 105.465 48.6777 106.253 48.6777 107.225V110.341C48.6777 111.313 47.8894 112.101 46.9172 112.101Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M62.2359 64.9421C62.2923 65.037 62.8848 66.0699 62.2068 66.2853C62.2068 66.2853 62.2333 66.37 61.5869 66.3896C61.5869 66.3896 61.2893 67.313 60.3061 67.2275L56.7731 67.5165C55.7668 67.4242 53.4215 67.4105 51.82 65.4705C51.82 65.4705 34.7667 64.1503 32.7001 62.6344C30.6335 61.1184 26.2764 51.7885 26.2764 51.7885L31.6296 47.6946C31.6296 47.6946 34.771 56.35 37.1625 57.4376C39.5548 58.5252 52.0894 62.277 52.0894 62.277C52.0894 62.277 54.1739 60.325 55.064 60.2198C55.4436 60.1813 56.1199 60.3609 56.815 60.6071C56.4183 60.9312 56.1011 61.3245 55.8591 61.7435C55.6958 61.7512 55.5582 61.7836 55.4983 61.8486C55.435 61.9059 55.5146 61.9752 55.695 62.0461C56.3508 62.3266 58.3583 62.6985 59.5793 63.0354C61.1218 63.4672 62.347 64.0435 62.5052 64.2829C62.789 64.7129 62.3761 64.9472 62.2359 64.9421Z" fill="#F5C7B7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.5906 162.271C22.5906 162.271 22.7001 163.2 22.4137 163.851C22.2025 164.332 15.665 165.571 9.46434 164.452C8.92739 164.355 8.55802 163.855 8.5982 163.31L8.64865 162.607L22.5906 162.271Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.568 162.151C22.1679 160.975 15.9391 160.108 13.7614 159.98C11.3084 159.841 13.5793 158.331 11.5811 158.554C9.5804 158.79 9.5804 158.79 9.5804 158.79C9.5804 158.79 8.77498 160.127 8.66212 162.195C8.65528 162.329 8.64844 162.46 8.64844 162.607C8.64844 164.026 12.8243 163.887 16.6779 163.845C19.2446 163.817 21.3052 163.716 22.4159 162.613C22.574 162.455 22.6185 162.3 22.568 162.151Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.07178 153.368L9.08375 159.948C9.08375 159.948 15.3878 161.195 13.9659 159.994L14.4191 153.929L9.07178 153.368Z" fill="#F4BEAA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.6608 162.313C43.6608 162.313 43.8549 163.227 43.63 163.901C43.4642 164.401 37.0687 166.238 30.7911 165.694C30.2482 165.648 29.8344 165.183 29.8233 164.638L29.8096 163.932L43.6608 162.313Z" fill="#617792"/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.6265 162.196C43.1203 161.062 36.8385 160.773 34.6582 160.847C32.2025 160.934 34.3247 159.22 32.3556 159.628C30.3848 160.047 30.3848 160.047 30.3848 160.047C30.3848 160.047 29.7067 161.451 29.7845 163.523C29.7905 163.655 29.7957 163.788 29.8093 163.932C29.9393 165.346 34.0853 164.822 37.9184 164.425C40.4723 164.161 42.5141 163.869 43.5187 162.668C43.6615 162.499 43.6906 162.339 43.6265 162.196Z" fill="#92A7C0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M29.3784 154.695L29.9966 161.246C29.9966 161.246 36.3888 161.906 34.8617 160.841L34.7548 154.76L29.3784 154.695Z" fill="#F4BEAA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.4991 62.1111C85.1536 55.212 79.2437 48.654 70.7568 46.4737C62.3289 44.3088 54.0463 47.1517 50.3791 52.9479L49.7857 52.7931L48.8229 56.5433C46.7324 64.6788 53.1639 73.3615 63.1882 75.9368C73.2124 78.5121 83.0332 74.0044 85.1229 65.8681L86.1446 62.2787L85.4991 62.1111Z" fill="#E9CE52"/>
<path fillRule="evenodd" clipRule="evenodd" d="M71.7207 42.7244C81.7449 45.2997 88.2345 54.1423 86.1449 62.2787C84.0543 70.4142 74.1755 74.7628 64.1512 72.1875C54.1278 69.6122 47.6964 60.9295 49.786 52.7931C51.8757 44.6568 61.6973 40.1491 71.7207 42.7244Z" fill="#F6EE71"/>
<path fillRule="evenodd" clipRule="evenodd" d="M71.0528 45.3579C79.2866 47.4732 84.6168 54.7366 82.9 61.4186C81.1831 68.1014 73.0698 71.6728 64.8359 69.5583C56.6021 67.443 51.3198 60.3113 53.0366 53.6285C54.7535 46.9456 62.8198 43.2425 71.0528 45.3579Z" fill="#E9CE52"/>
<path fillRule="evenodd" clipRule="evenodd" d="M33.3013 80.6548C33.3013 80.6548 12.0003 72.7467 2.31802 81.8202C0.450655 94.7882 7.23608 118.536 7.40196 129.025C7.53705 137.521 7.60887 145.624 8.08255 156.995L14.9142 156.82C14.9142 156.82 17.1714 145.568 18.2958 129.751C18.7976 122.683 18.8139 112.737 17.8152 101.414L19.6621 101.498C19.6621 101.498 24.3578 118.713 25.5574 127.988C26.6989 136.806 27.736 147.077 28.2884 156.995L36.0417 156.692C36.0417 156.692 37.4191 137.428 37.3558 127.572C37.2695 114.385 34.7694 89.0485 33.3013 80.6548Z" fill="#1C4158"/>
<path fillRule="evenodd" clipRule="evenodd" d="M80.0941 65.2755C80.1745 65.3508 81.027 66.1819 80.4344 66.5743C80.4344 66.5743 80.4832 66.6495 79.8667 66.8453C79.8667 66.8453 79.8334 67.8141 78.8638 68.0013L75.5454 69.2454C74.5519 69.4335 72.2912 70.0611 70.2212 68.6332C70.2212 68.6332 53.4586 72.0319 51.0552 71.1393C48.6526 70.2466 41.9082 62.4668 41.9082 62.4668L45.9362 57.0631C45.9362 57.0631 51.3262 64.5291 53.9246 64.9198C56.523 65.3106 69.6056 65.4884 69.6056 65.4884C69.6056 65.4884 71.0763 63.0405 71.9048 62.6968C72.2588 62.5549 72.9582 62.5429 73.6943 62.5882C73.4011 63.0089 73.2044 63.474 73.0856 63.9443C72.9308 63.9964 72.8077 64.0648 72.7675 64.1435C72.7213 64.2162 72.8188 64.2615 73.0103 64.2803C73.7183 64.3692 75.7515 64.1777 77.0178 64.1674C78.6192 64.1614 79.9556 64.3803 80.1736 64.5676C80.5644 64.9036 80.2318 65.2422 80.0941 65.2755Z" fill="#F6CDBA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.2623 67.0104C44.2396 62.5882 49.4834 57.4308 49.4834 57.4308C45.2972 54.3715 42.0772 39.6532 29.806 36.1203C28.0617 35.6175 22.5144 33.828 18.9857 36.9488C0 53.7388 0 86.4184 0 86.4184C14.4567 88.9271 34.9694 84.0167 34.9694 84.0167C34.2726 79.7194 33.5698 69.0701 37.1626 57.4376C39.0163 61.9589 43.2623 67.0104 43.2623 67.0104Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M40.1878 21.0241C40.1878 21.0241 40.2493 24.0713 40.1159 25.418C39.9826 26.7629 41.2138 27.3862 41.2317 27.8197C41.2471 28.1917 40.2211 28.9107 40.2211 28.9107C40.2211 28.9107 40.8248 33.0678 39.4388 33.9622C38.0528 34.8548 34.4634 33.8074 34.4634 33.8074L33.1706 38.2356C33.1706 38.2356 27.9028 39.3804 24.1621 34.9985L25.3856 30.8097C25.3856 30.8097 24.2579 22.5178 28.4894 18.9934C32.7217 15.4681 40.1878 21.0241 40.1878 21.0241Z" fill="#F6CDBA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M43.9242 19.3602C45.0178 17.5091 44.5526 16.0778 42.41 15.9033C38.1802 15.5579 36.8096 11.4684 30.1729 12.5594C28.9255 12.7654 27.7148 13.1998 26.599 13.8214C26.7811 13.1998 27.1111 12.4781 27.1111 12.4781C26.3544 13.0279 25.9355 13.7889 25.7106 14.3395C25.0232 14.2762 23.3439 14.1899 22.8899 14.8115C22.8899 14.8115 24.0151 14.7097 24.7735 15.0894C21.1012 18.1888 19.2792 23.6079 22.518 29.2784C24.4426 32.6463 29.0443 31.5579 31.0707 29.5101C31.0707 29.5101 28.5441 29.015 28.8648 27.2263C29.1136 25.8335 30.4662 25.2051 31.2349 26.1131C32.4037 27.4948 32.1651 27.6453 32.7474 27.7291C32.7474 27.7291 32.8671 25.1179 33.8478 23.2411C33.8478 23.2411 40.44 24.0842 42.7674 21.3002C42.7674 21.3002 41.5438 21.6362 41.2138 21.5832C41.2138 21.5832 42.7947 21.2694 43.9242 19.3602Z" fill="#18344A"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.90037 88.9271C5.90037 88.9271 11.422 89.8608 14.4565 88.9271V94.2958L10.178 96.7856L6.67587 95.3081C6.67587 95.3081 5.35829 90.5602 5.90037 88.9271Z" fill="#122435"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.5523 88.6158C22.5523 88.6158 28.0739 89.1203 31.1083 88.1875L31.4589 94.3342L27.3343 96.9018L23.3269 94.996C23.3269 94.996 22.0102 90.2498 22.5523 88.6158Z" fill="#122435"/>
<defs>
<linearGradient id="paint0_linear" x1="53.0439" y1="-43.1861" x2="151.475" y2="142.382" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFFFFE"/>
<stop offset="1" stopColor="#9DC8E1"/>
</linearGradient>
</defs>
</svg>
  )
}

export default SelfEncashmentImg;