import React from "react";

const MainRegImg = () => {
  return (
<svg className="main-reg-img" width="421" height="33vh" viewBox="0 0 429 224" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
<path d="M184.779 40.8292L160.703 42.1143L170.209 38.5388L200.483 35.4341L184.779 40.8292Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M201.087 35.3011C201.155 35.6086 200.981 35.9179 200.684 36.02L184.98 41.4152C184.926 41.4338 184.869 41.4448 184.812 41.4478L160.736 42.7329C160.431 42.7491 160.16 42.5403 160.098 42.2411C160.035 41.9418 160.2 41.6417 160.486 41.5342L169.992 37.9587C170.041 37.94 170.093 37.9278 170.146 37.9224L200.42 34.8177C200.733 34.7856 201.02 34.9936 201.087 35.3011ZM170.352 39.1468L164.676 41.2818L184.66 40.2151L195.182 36.6005L170.352 39.1468Z" fill="#C2D9E9"/>
<path d="M137.695 56.767L201.717 33.7173C204.798 32.6057 208.106 32.2658 211.35 32.7154L214.767 33.1933C216.361 33.4163 216.573 35.6325 215.053 36.1564L162.622 54.2677C157.834 55.9209 152.809 56.767 147.745 56.767H137.695Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M211.265 33.329C208.119 32.893 204.913 33.2227 201.926 34.3L201.926 34.3002L141.244 56.1474H147.745C152.74 56.1474 157.697 55.3128 162.421 53.6819C162.421 53.6819 162.421 53.6819 162.421 53.6819L214.852 35.5706C215.757 35.2584 215.63 33.9395 214.682 33.8068L211.265 33.329ZM137.499 56.179L201.507 33.1343C204.684 31.9884 208.092 31.6384 211.435 32.1016L214.853 32.5796C217.091 32.8929 217.388 36.0061 215.255 36.742C215.255 36.742 215.255 36.7419 215.255 36.742L162.824 54.8533C157.971 56.5288 152.878 57.3864 147.745 57.3864H137.695C137.353 57.3864 137.076 57.1091 137.076 56.7669C137.076 56.4932 137.253 56.261 137.499 56.179Z" fill="#C2D9E9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M140.612 51.5624C140.683 51.5418 140.757 51.5342 140.831 51.5399L148.838 52.1559C149.127 52.1781 149.362 52.3983 149.403 52.6857C149.444 52.9731 149.28 53.2505 149.009 53.3531L138.461 57.3464C138.331 57.3955 138.189 57.3995 138.057 57.3578L131.646 55.3364C131.386 55.2543 131.21 55.0112 131.214 54.738C131.217 54.4648 131.399 54.226 131.661 54.1502L140.612 51.5624ZM140.847 52.7839L133.973 54.7712L138.224 56.1115L145.973 53.1782L140.847 52.7839Z" fill="#C2D9E9"/>
<path d="M181.836 43.9586L164.05 56.4977L172.885 53.4603L197.529 38.5352L181.836 43.9586Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M198.089 38.2724C198.223 38.5593 198.12 38.9014 197.849 39.0653L173.204 53.9905C173.167 54.0133 173.127 54.032 173.085 54.0463L164.25 57.0837C163.959 57.1838 163.639 57.0541 163.499 56.7794C163.359 56.5046 163.442 56.1686 163.694 55.9911L181.479 43.452C181.527 43.4185 181.579 43.3919 181.634 43.373L197.327 37.9495C197.626 37.8462 197.955 37.9855 198.089 38.2724ZM182.12 44.5157L167.96 54.4982L172.621 52.8959L192.225 41.0236L182.12 44.5157Z" fill="#C2D9E9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.1902 0.337402H168.078V1.57645H99.427V115.264H98.1902V0.337402Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M336.154 0.337402H388.03V1.57645H337.39V115.264H336.154V0.337402Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M177.305 0.337402H248.063V1.57645H178.542V115.264H177.305V0.337402Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M259.046 20.7217V115.264H257.809V20.7217H259.046Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M35.4049 158.291C35.4049 136.347 53.1635 118.556 75.0684 118.556H388.03V119.795H75.0684C53.8466 119.795 36.6417 137.031 36.6417 158.291C36.6417 179.55 53.8466 196.786 75.0684 196.786H388.03V198.025H75.0684C53.1635 198.025 35.4049 180.235 35.4049 158.291Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M42.1757 158.291C42.1757 136.347 59.9343 118.556 81.8392 118.556H388.029V119.795H81.8392C60.6174 119.795 43.4125 137.031 43.4125 158.291C43.4125 179.55 60.6174 196.786 81.8392 196.786H388.029V198.025H81.8392C59.9343 198.025 42.1757 180.235 42.1757 158.291Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M31.1997 158.291C31.1997 134.187 50.7076 114.645 74.7681 114.645H388.03V115.884H74.7681C51.3907 115.884 32.4366 134.872 32.4366 158.291C32.4366 181.71 51.3907 200.698 74.7681 200.698H388.026V201.937H74.7681C50.7076 201.937 31.1997 182.394 31.1997 158.291Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M388.03 223.723H31.8215V222.484H388.03V223.723Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M388.03 201.937H31.8215V200.698H388.03V201.937Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M388.03 219.85H31.8215V218.611H388.03V219.85Z" fill="#CAE6F9"/>
<path d="M103.519 43.0806C105.342 43.0806 106.82 40.3973 106.82 37.0872C106.82 33.7771 105.342 31.0938 103.519 31.0938C101.696 31.0938 100.218 33.7771 100.218 37.0872C100.218 40.3973 101.696 43.0806 103.519 43.0806Z" fill="#6AD0CC"/>
<path d="M103.523 31.0938H67.8983V43.0771H103.523V31.0938Z" fill="#6AD0CC"/>
<path d="M71.9161 46.6631H97.4691V123.972H64.5976V53.9982C64.5941 49.9448 67.8734 46.6631 71.9161 46.6631Z" fill="#FE6D54"/>
<path d="M94.9672 43.0771H80.7083C78.5704 43.0771 76.8353 44.8154 76.8353 46.9571V62.7142L94.9672 43.0771Z" fill="#2F4882"/>
<path d="M98.3732 216.69L102.614 216.69L102.614 206.285L98.3732 206.285L98.3732 216.69Z" fill="#BCBEC0"/>
<path d="M98.3738 214.596H106.636C106.636 214.596 109.467 219.085 113.947 218.14C118.428 217.195 119.987 222.866 119.139 222.866C115.368 222.866 98.3738 222.866 98.3738 222.866V214.596Z" fill="#6AD0CC"/>
<path d="M83.6972 216.69L87.9378 216.69L87.9378 206.285L83.6972 206.285L83.6972 216.69Z" fill="#BCBEC0"/>
<path d="M115.499 80.9248H96.2855L98.2715 211.977V215.91H100.947C102.844 215.91 104.427 214.451 104.583 212.557L115.499 80.9248Z" fill="#FBB040"/>
<path d="M87.9387 214.596H81.8075C81.8075 214.596 79.7084 219.085 76.3796 218.14C73.0543 217.195 71.8987 222.866 72.5278 222.866C75.3265 222.866 87.9387 222.866 87.9387 222.866V214.596Z" fill="#6AD0CC"/>
<path d="M102.251 80.9244L88.0375 215.906H86.0268C83.2563 215.906 80.9947 213.69 80.9346 210.914L78.0722 80.9209H102.251V80.9244Z" fill="#FBB040"/>
<path d="M94.9672 45.251L109.82 49.0035C116.612 50.3629 117.778 56.8484 117.778 56.8484L122.899 77.537C123.372 79.4522 123.22 81.4807 122.4 83.2755C120.626 87.1519 116.693 91.4603 110.255 76.8714C106.915 69.3062 100.996 59.8115 94.9672 45.251Z" fill="#0368AF"/>
<path d="M111.986 123.275C111.986 123.275 116.644 86.3445 113.866 58.466C113.449 54.2851 108.113 51.2795 105.569 49.8174C94.1122 43.2363 93.0308 45.0949 82.5955 46.1817C75.8459 46.8827 70.6547 53.2478 70.6547 53.2478C70.6547 53.2478 74.0401 94.508 74.973 107.065C75.1285 109.168 76.0332 111.14 77.5103 112.641C92.6669 128.047 111.986 123.275 111.986 123.275Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M70.9271 71.5101L73.393 71.3145L76.074 105.235C76.0748 105.242 76.0764 105.255 76.0789 105.275C76.0843 105.316 76.0942 105.385 76.1105 105.479C76.1431 105.666 76.2013 105.953 76.3008 106.32C76.4997 107.053 76.8633 108.106 77.517 109.329C78.8189 111.764 81.2905 114.912 85.9848 117.569L84.7681 119.727C79.642 116.826 76.8465 113.324 75.3363 110.499C74.584 109.091 74.1554 107.861 73.9136 106.969C73.7927 106.523 73.7184 106.162 73.6736 105.904C73.6512 105.775 73.6361 105.672 73.6263 105.598C73.6215 105.561 73.6179 105.53 73.6153 105.508C73.6141 105.496 73.6131 105.487 73.6123 105.479L73.6113 105.469L73.6109 105.465L73.6107 105.463C73.6107 105.463 73.6106 105.462 74.8422 105.348L73.6106 105.462L73.6099 105.454L70.9271 71.5101Z" fill="#437ADD"/>
<path fillRule="evenodd" clipRule="evenodd" d="M110.827 216.893C110.941 217.215 110.772 217.569 110.45 217.683C109.372 218.065 108.355 218.82 107.588 219.508C107.21 219.848 106.902 220.164 106.69 220.393C106.584 220.507 106.502 220.6 106.447 220.663C106.42 220.694 106.4 220.719 106.387 220.734L106.372 220.751L106.369 220.755C106.369 220.755 106.369 220.755 106.369 220.755C106.369 220.755 106.369 220.755 105.89 220.363L105.412 219.971C105.195 220.236 105.235 220.626 105.499 220.843C105.764 221.059 106.153 221.02 106.369 220.755M105.89 220.363C105.412 219.971 105.411 219.971 105.412 219.971L105.413 219.97L105.414 219.968L105.42 219.961C105.425 219.955 105.431 219.947 105.44 219.937C105.457 219.917 105.481 219.888 105.512 219.852C105.575 219.779 105.666 219.676 105.783 219.55C106.015 219.299 106.35 218.956 106.763 218.586C107.578 217.853 108.738 216.976 110.038 216.515C110.36 216.401 110.713 216.57 110.827 216.893" fill="white"/>
<path d="M93.6457 45.1338C93.6457 45.1338 75.2027 45.725 70.6583 50.2776C67.8736 53.0672 62.1594 69.0969 58.1556 76.4179C56.1414 80.0996 58.8059 84.5991 63.0005 84.5991C70.1918 84.5991 91.7586 84.5991 91.7586 84.5991V75.8939L77.1499 72.3184L83.8323 60.4342" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M108.654 215.699C108.768 216.022 108.599 216.376 108.277 216.49C107.199 216.872 106.181 217.626 105.415 218.315C105.036 218.655 104.729 218.97 104.516 219.2C104.41 219.314 104.329 219.407 104.274 219.47C104.247 219.501 104.226 219.525 104.213 219.541L104.199 219.558L104.196 219.562C104.196 219.562 104.196 219.562 104.196 219.562C104.196 219.562 104.196 219.562 103.717 219.17L103.238 218.778C103.022 219.043 103.061 219.433 103.326 219.65C103.59 219.866 103.98 219.827 104.196 219.562M103.717 219.17C103.238 218.778 103.238 218.778 103.238 218.778L103.239 218.777L103.241 218.775L103.247 218.768C103.251 218.762 103.258 218.754 103.266 218.744C103.283 218.724 103.308 218.695 103.339 218.659C103.402 218.586 103.493 218.483 103.609 218.357C103.842 218.106 104.177 217.763 104.589 217.393C105.405 216.66 106.564 215.782 107.864 215.322C108.186 215.208 108.54 215.377 108.654 215.699" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M90.7135 78.1195L78.8447 45.4222L82.3318 44.1519L95.1997 79.6019L71.0994 95.5869L69.051 92.4875L90.7135 78.1195Z" fill="#FE6D54"/>
<path d="M62.997 84.5991H87.6984L87.9493 74.9594L77.1499 72.3149" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M78.786 218.289L78.6103 217.695C78.2828 217.792 78.096 218.137 78.193 218.465C78.2892 218.79 78.6284 218.977 78.9533 218.885C78.9557 218.884 78.9618 218.883 78.9716 218.881C78.9932 218.877 79.0322 218.869 79.0867 218.864C79.1958 218.852 79.3668 218.845 79.5859 218.872C80.0184 218.927 80.6626 219.118 81.4072 219.714C81.674 219.928 82.0631 219.884 82.2763 219.617C82.4895 219.35 82.4461 218.96 82.1793 218.746C81.2524 218.004 80.393 217.725 79.7397 217.643C79.4159 217.602 79.1484 217.611 78.9548 217.632C78.8581 217.642 78.7796 217.656 78.7216 217.668C78.6926 217.674 78.6686 217.679 78.65 217.684C78.6407 217.686 78.6327 217.688 78.6261 217.69L78.6172 217.693L78.6135 217.694L78.6118 217.694L78.611 217.694C78.6107 217.695 78.6103 217.695 78.786 218.289Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M80.2914 216.689L80.1157 216.095C79.7882 216.192 79.6014 216.537 79.6984 216.865C79.7946 217.19 80.1338 217.377 80.4587 217.285C80.4611 217.284 80.4673 217.283 80.477 217.281C80.4986 217.276 80.5376 217.269 80.5921 217.264C80.7012 217.252 80.8722 217.245 81.0913 217.272C81.5238 217.327 82.168 217.518 82.9125 218.114C83.1793 218.328 83.5685 218.284 83.7817 218.017C83.9949 217.75 83.9515 217.36 83.6847 217.146C82.7577 216.404 81.8983 216.125 81.2451 216.043C80.9212 216.002 80.6538 216.011 80.4602 216.032C80.3635 216.042 80.285 216.055 80.227 216.067C80.198 216.073 80.174 216.079 80.1554 216.084C80.1461 216.086 80.1381 216.088 80.1315 216.09L80.1225 216.093L80.1189 216.094L80.1172 216.094L80.1164 216.094C80.116 216.094 80.1157 216.095 80.2914 216.689Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M80.369 129.128L81.8779 199.021L79.4048 199.075L77.8959 129.182L80.369 129.128Z" fill="#FDD08C"/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.6353 129.146L98.5364 188.556L97.2997 188.575L96.3986 129.165L97.6353 129.146Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.646 212.634L97.4411 199.057L98.6778 199.039L98.8827 212.615L97.646 212.634Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M112.602 123.332C112.602 123.333 112.602 123.333 111.986 123.275C111.37 123.218 111.37 123.217 111.37 123.217L111.38 123.114C111.386 123.045 111.396 122.942 111.408 122.807C111.432 122.537 111.468 122.138 111.514 121.623C111.605 120.592 111.734 119.095 111.887 117.231C112.194 113.504 112.597 108.31 112.98 102.446C113.747 90.712 114.436 76.3161 114.131 65.61L115.368 65.5747C115.674 76.3431 114.982 90.7886 114.214 102.527C113.83 108.4 113.427 113.6 113.12 117.333C112.967 119.199 112.837 120.699 112.746 121.732C112.7 122.249 112.664 122.649 112.64 122.919C112.627 123.055 112.618 123.158 112.611 123.228L112.602 123.332Z" fill="#116097"/>
<path d="M98.8085 40.7301L98.6247 50.1574L88.4332 45.881L90.1931 40.6699L98.8085 40.7301Z" fill="#FFFCF8"/>
<path d="M96.6069 42.9782C96.6069 42.9782 100.137 43.1765 101.756 43.0136C102.897 42.9004 103.106 41.5374 103.053 38.5566C103.046 38.2238 102.943 35.2926 102.894 33.9438C102.667 27.7061 97.8226 27.7415 97.8226 27.7415C91.9034 27.1255 90.2779 28.6938 90.2779 28.6938L90.1789 42.9322" fill="#FFFCF8"/>
<path d="M90.027 41.1726C90.027 41.1726 90.1684 40.376 90.755 39.6751C91.0342 39.3388 89.3132 36.1314 92.2215 36.3544C95.1299 36.5775 90.2779 28.6972 102.198 31.5328C103.063 32.0355 103.063 26.874 99.0948 26.7501C98.4234 26.6262 92.0095 25.8438 89.2355 28.5768C86.4614 31.3098 90.027 41.1726 90.027 41.1726Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M104.966 44.8672L108.673 44.707L110.122 78.3709L117.146 91.9689L113.851 93.6773L106.45 79.3507L104.966 44.8672Z" fill="#FE6D54"/>
<path fillRule="evenodd" clipRule="evenodd" d="M113.513 218.144C113.395 217.535 113.395 217.535 113.394 217.536L113.393 217.536L113.391 217.536L113.387 217.537L113.375 217.54C113.366 217.542 113.354 217.544 113.339 217.548C113.31 217.555 113.271 217.564 113.224 217.578C113.129 217.605 113 217.647 112.847 217.71C112.542 217.835 112.138 218.044 111.734 218.384C110.996 219.004 110.301 220.023 110.158 221.63H100.494C100.153 221.63 99.8759 221.908 99.8759 222.25C99.8759 222.592 100.153 222.869 100.494 222.869H111.368V222.25C111.368 220.652 111.976 219.798 112.529 219.333C112.815 219.093 113.102 218.944 113.315 218.857C113.422 218.813 113.508 218.785 113.564 218.769C113.593 218.761 113.613 218.756 113.625 218.753C113.63 218.752 113.634 218.751 113.635 218.751C113.968 218.684 114.185 218.36 114.12 218.025C114.055 217.689 113.73 217.47 113.395 217.535L113.513 218.144Z" fill="#42B7AC"/>
<path d="M117.008 86.6527L112.668 84.5994V73.2852H115.103C117.955 73.2852 120.616 74.9667 121.63 77.636C122.287 79.3636 122.248 81.0522 120.831 82.9391L117.008 86.6527Z" fill="#0368AF"/>
<path d="M107.901 73.2852H112.668V84.5994H103.904V77.289C103.904 75.0765 105.693 73.2852 107.901 73.2852Z" fill="#FFFCF8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M77.9161 219.739C78.1568 219.496 78.5483 219.494 78.7907 219.736C79.349 220.291 79.6847 220.851 79.882 221.278C79.925 221.372 79.9614 221.459 79.9922 221.538H87.9386C88.2802 221.538 88.557 221.816 88.557 222.158C88.557 222.5 88.2802 222.777 87.9386 222.777H79.0264L78.9319 222.277C78.9314 222.274 78.9301 222.269 78.928 222.26C78.9236 222.242 78.9155 222.211 78.9026 222.169C78.8768 222.084 78.8319 221.956 78.7592 221.798C78.6137 221.482 78.3574 221.051 77.9192 220.615C77.6769 220.374 77.6755 219.981 77.9161 219.739Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M95.667 65.5923V49.1768H96.9038V65.5923H95.667Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M98.1902 73.1187V49.1768H99.4269V73.1187H98.1902Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M96.9013 47.4194L85.1708 42.499L83.7377 45.9277L97.9083 51.8716C98.4662 52.1056 99.1029 52.0525 99.6145 51.7293C100.126 51.4061 100.448 50.8534 100.478 50.2482L100.824 43.168L97.1181 42.986L96.9013 47.4194Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.1208 51.3833C98.3952 51.9148 99.8136 51.0206 99.8866 49.637L99.9395 46.6523L101.176 46.6742L101.123 49.6787L101.122 49.6882C101.013 51.9366 98.7075 53.3945 96.6362 52.5233L96.6299 52.5207L83.3719 46.5989L83.8756 45.4673L97.1208 51.3833Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M76.6111 72.011L82.0108 62.4102L83.0884 63.0184L77.6887 72.6192L76.6111 72.011Z" fill="#116097"/>
<path d="M92.4653 73.2852H87.6982V84.5994H96.462V77.289C96.462 75.0765 94.6739 73.2852 92.4653 73.2852Z" fill="#FFFCF8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M116.742 87.2119L112.404 85.1594L112.932 84.0391L117.272 86.0923L117.273 86.0927C117.273 86.0928 117.273 86.093 117.274 86.0932C117.276 86.0941 117.28 86.0959 117.286 86.0986C117.298 86.104 117.318 86.1127 117.345 86.1237C117.4 86.146 117.482 86.1774 117.587 86.2106C117.798 86.2776 118.086 86.3479 118.402 86.3684C119.025 86.4087 119.704 86.26 120.201 85.5497L121.214 86.2614C120.39 87.4379 119.219 87.6628 118.322 87.6048C117.879 87.5762 117.489 87.4794 117.213 87.3917C117.074 87.3475 116.961 87.3046 116.88 87.2719C116.84 87.2555 116.807 87.2415 116.784 87.2311C116.772 87.2259 116.763 87.2216 116.755 87.2182L116.746 87.214L116.743 87.2125L116.742 87.2119Z" fill="#2F4882"/>
<path fillRule="evenodd" clipRule="evenodd" d="M93.7545 93.7696L83.3474 102.57L81.7518 100.677L92.1589 91.876L93.7545 93.7696Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M103.126 95.2002L110.723 106.677L108.662 108.047L101.064 96.5698L103.126 95.2002Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M84.4543 162.025H93.6457V163.264H84.4543V162.025Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M85.5381 163.083L83.0892 165.537L82.2146 164.66L84.6635 162.207L85.5381 163.083Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M97.653 162.025H106.844V163.264H97.653V162.025Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M107.463 162.139V165.608H106.226V162.139H107.463Z" fill="white"/>
<path d="M67.8982 43.0806C69.7211 43.0806 71.1988 40.3973 71.1988 37.0872C71.1988 33.7771 69.7211 31.0938 67.8982 31.0938C66.0753 31.0938 64.5976 33.7771 64.5976 37.0872C64.5976 40.3973 66.0753 43.0806 67.8982 43.0806Z" fill="#A6E3E0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M72.7786 108.753V124.592H64.5942V123.353H71.5417V108.753H72.7786Z" fill="#EA3F28"/>
<path fillRule="evenodd" clipRule="evenodd" d="M67.8947 35.0797C67.7599 35.5681 67.6687 36.2782 67.6687 37.0874C67.6687 37.8965 67.7599 38.6066 67.8947 39.0951C67.8959 39.0995 67.8972 39.1039 67.8984 39.1082C67.8996 39.1039 67.9008 39.0995 67.902 39.0951C68.0368 38.6066 68.1281 37.8965 68.1281 37.0874C68.1281 36.2782 68.0368 35.5681 67.902 35.0797C67.9008 35.0752 67.8996 35.0709 67.8984 35.0665C67.8972 35.0709 67.8959 35.0752 67.8947 35.0797ZM68.1149 34.5565C68.115 34.5568 68.1124 34.56 68.1072 34.5649C68.1123 34.5585 68.1149 34.5561 68.1149 34.5565ZM67.6895 34.5649C67.6843 34.56 67.6818 34.5568 67.6818 34.5565C67.6818 34.5561 67.6844 34.5585 67.6895 34.5649ZM67.6818 39.6183C67.6818 39.6179 67.6843 39.6147 67.6895 39.6098C67.6844 39.6162 67.6818 39.6186 67.6818 39.6183ZM68.1072 39.6098C68.1124 39.6147 68.115 39.6179 68.1149 39.6183C68.1149 39.6186 68.1123 39.6162 68.1072 39.6098ZM67.0506 33.917C67.1847 33.7116 67.4578 33.395 67.8984 33.395C68.3389 33.395 68.6121 33.7116 68.7461 33.917C68.897 34.1483 69.0093 34.442 69.0941 34.7495C69.2663 35.3731 69.3649 36.1995 69.3649 37.0874C69.3649 37.9753 69.2663 38.8016 69.0941 39.4253C69.0093 39.7327 68.897 40.0264 68.7461 40.2577C68.6121 40.4631 68.3389 40.7797 67.8984 40.7797C67.4578 40.7797 67.1847 40.4631 67.0506 40.2577C66.8997 40.0264 66.7875 39.7327 66.7026 39.4253C66.5305 38.8016 66.4319 37.9753 66.4319 37.0874C66.4319 36.1995 66.5305 35.3731 66.7026 34.7495C66.7875 34.442 66.8997 34.1483 67.0506 33.917Z" fill="#6AD0CC"/>
<path d="M273.619 145.238H245.292V164.783H273.619V145.238Z" fill="#F7941D"/>
<path d="M239.582 58.2361C248.064 58.2361 254.94 51.3478 254.94 42.8507C254.94 34.3536 248.064 27.4653 239.582 27.4653C231.1 27.4653 224.224 34.3536 224.224 42.8507C224.224 51.3478 231.1 58.2361 239.582 58.2361Z" fill="#0368AF"/>
<path d="M259.873 121.987L256.508 122.111L256.321 116.97C256.265 115.398 257.487 114.078 259.056 114.021L262.399 113.897L262.587 119.055C262.65 120.617 261.431 121.93 259.873 121.987Z" fill="white"/>
<path d="M252.133 62.1689C259.459 65.6949 262.551 114.832 262.551 114.832C262.551 114.832 260.283 115.239 256.364 114.29L250.939 75.9188C250.939 75.9152 247.14 59.7616 252.133 62.1689Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M255.044 119.737C256.324 118.283 257.814 117.388 259.456 117.388C261.098 117.388 262.588 118.283 263.869 119.737C265.151 121.192 266.276 123.262 267.209 125.767C269.077 130.78 270.22 137.664 270.22 145.238H268.983C268.983 137.773 267.855 131.042 266.051 126.2C265.148 123.776 264.088 121.858 262.942 120.557C261.795 119.255 260.615 118.627 259.456 118.627C258.297 118.627 257.118 119.255 255.971 120.557C254.825 121.858 253.765 123.776 252.862 126.2C251.057 131.042 249.928 137.773 249.928 145.238H248.692C248.692 137.664 249.835 130.78 251.703 125.766C252.636 123.262 253.762 121.192 255.044 119.737Z" fill="#FBB040"/>
<path d="M255.671 106.686C247.897 106.686 244.766 106.686 236.458 106.686C251.342 121.427 244.808 219.018 244.808 219.018H244.985C248.296 219.018 251.056 216.48 251.339 213.173C251.342 213.173 260.929 113.685 255.671 106.686Z" fill="#FE6D54"/>
<path d="M246.854 106.686L234.574 219.018C231.722 219.018 229.715 219.453 229.273 213.902C235.945 140.965 220.276 121.14 229.595 106.686C238.458 106.686 246.854 106.686 246.854 106.686Z" fill="#FE6D54"/>
<path d="M243.077 179.652H247.349C251.215 179.652 254.261 182.951 253.964 186.81L251.657 216.731C251.646 216.855 251.639 216.979 251.639 217.103C251.628 218.855 252.897 220.36 254.579 220.827L258.042 221.79C258.678 222.225 258.371 223.224 257.601 223.224H249.519L244.829 219.149V223.224H243.798L243.077 179.652Z" fill="#0368AF"/>
<path d="M239.098 179.652H232.953C230.055 179.652 227.73 182.052 227.821 184.955L228.821 216.731C228.966 218.218 228.288 219.662 227.058 220.501L225.175 221.79C224.539 222.225 224.846 223.224 225.616 223.224H230.959L235.649 219.149V223.224H236.681L239.098 179.652Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M246.321 179.531V194.81H245.084V179.531H246.321Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M235.328 194.769L236.357 179.49L237.591 179.573L236.562 194.852L235.328 194.769Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M236.313 219.028V223.103H235.076V219.028H236.313Z" fill="#2F4882"/>
<path fillRule="evenodd" clipRule="evenodd" d="M244.6 217.671L250.312 222.635L249.502 223.571L245.836 220.386V223.103H244.6V217.671Z" fill="#2F4882"/>
<path fillRule="evenodd" clipRule="evenodd" d="M244.988 219.638H244.812V218.398H244.988V219.638Z" fill="#EA3F28"/>
<path fillRule="evenodd" clipRule="evenodd" d="M245.084 179.53C245.115 164.469 244.437 137.329 243.368 122.218L244.601 122.13C245.674 137.28 246.352 164.452 246.321 179.533L245.084 179.53Z" fill="#EA3F28"/>
<path fillRule="evenodd" clipRule="evenodd" d="M229.651 140.312C231.01 151.227 232.566 163.718 232.067 176.535L229.596 176.439C230.086 163.843 228.56 151.583 227.201 140.664C226.947 138.622 226.699 136.626 226.471 134.685C225.75 128.552 225.228 122.926 225.392 118.103C225.556 113.295 226.405 109.111 228.575 105.979L230.607 107.393C228.841 109.942 228.023 113.553 227.865 118.187C227.707 122.805 228.207 128.266 228.927 134.395C229.152 136.307 229.398 138.282 229.651 140.312Z" fill="#FE8A76"/>
<path d="M228.256 64.3003L217.852 89.6689L200.243 109.996L202.6 111.628C204.279 112.789 206.53 112.669 208.078 111.334L219.05 101.858C221.315 100.158 223.153 97.9528 224.411 95.411L234.836 73.8622L228.256 64.3003Z" fill="#FBB040"/>
<path d="M203.059 114.606H193.129C192.006 114.606 191.734 113.037 192.79 112.659L200.232 109.975L203.745 112.421C204.706 113.09 204.233 114.606 203.059 114.606Z" fill="white"/>
<path d="M229.591 106.686C240.797 106.686 255.671 106.686 255.671 106.686L251.762 98.013C248.928 89.7539 255.144 68.6866 255.144 68.6866C255.455 65.9926 253.427 62.5091 250.936 61.4506L242.628 59.1885H235.938L230.369 62.3109C228.082 63.5924 226.87 66.1908 227.354 68.7681C227.354 68.7681 234.828 89.3362 232.846 98.0095C232.853 98.013 230.832 104.208 229.591 106.686Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M230.127 106.996C230.127 106.996 230.126 106.996 229.591 106.686C229.056 106.376 229.056 106.376 229.056 106.376L229.056 106.375L229.059 106.371L229.071 106.35C229.082 106.331 229.098 106.301 229.119 106.262C229.163 106.183 229.226 106.064 229.307 105.908C229.468 105.596 229.695 105.135 229.956 104.546C230.479 103.368 231.135 101.683 231.669 99.6618C232.74 95.6114 233.305 90.2585 231.367 84.9279L232.529 84.5039C234.585 90.1584 233.972 95.7905 232.865 99.9789C232.311 102.077 231.63 103.825 231.086 105.05C230.814 105.662 230.577 106.145 230.406 106.477C230.32 106.642 230.252 106.77 230.204 106.858C230.18 106.902 230.161 106.935 230.148 106.959L230.133 106.985L230.128 106.993L230.127 106.996Z" fill="#FDD08C"/>
<path d="M242.659 56.6781L242.903 61.1032C243.03 63.4043 240.278 64.6681 238.62 63.0751L235.274 59.8607L237.016 56.6533L242.659 56.6781Z" fill="#FFFCF8"/>
<path d="M242.345 57.9281C242.345 57.9281 245.43 56.6466 246.971 55.5916C247.918 54.9437 249.692 54.1189 249.692 51.1346C249.692 42.2134 246.158 41.7497 246.158 41.7497C240.239 41.1337 236.917 42.9144 236.917 42.9144L236.818 57.1528" fill="#FFFCF8"/>
<path d="M246.78 42.0046C238.444 41.2222 242.617 49.9345 238.857 47.1484C237.62 45.9625 235.921 49.6796 238.034 50.6602C239.974 51.5594 239.447 56.2784 236.408 57.7759C236.408 57.7759 231.825 49.4743 234.094 44.8509C237.119 38.6769 242.907 39.3353 246.78 42.0046Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M228.86 64.4398C228.862 64.435 228.865 64.429 228.869 64.4218C228.896 64.3697 228.965 64.2552 229.123 64.0847C229.441 63.7413 230.102 63.1861 231.443 62.4721L230.863 61.3779C229.445 62.1331 228.657 62.7656 228.216 63.2426C227.994 63.4824 227.857 63.6863 227.772 63.8499C227.729 63.9318 227.7 64.0033 227.68 64.0635C227.675 64.0782 227.671 64.0922 227.667 64.1054L217.319 89.3396L201.932 107.102L202.866 107.914L218.386 89.9983L228.856 64.4672L228.86 64.4398Z" fill="#FDD08C"/>
<path fillRule="evenodd" clipRule="evenodd" d="M250.796 68.5568L250.288 67.9133L251.258 67.1445L251.767 67.7896C253.691 70.234 254.626 72.8554 253.92 75.9907L251.91 87.6399C251.908 87.6541 251.907 87.6745 251.904 87.7008C251.899 87.7573 251.892 87.841 251.884 87.9487C251.866 88.1643 251.843 88.4759 251.819 88.8585C251.77 89.6243 251.717 90.6714 251.702 91.7993C251.686 92.9289 251.708 94.1294 251.806 95.2046C251.905 96.2966 252.078 97.1959 252.333 97.7616L251.206 98.2714C250.862 97.5077 250.676 96.4352 250.574 95.3171C250.471 94.1823 250.449 92.9346 250.465 91.7822C250.481 90.628 250.535 89.5593 250.584 88.7796C250.609 88.3895 250.633 88.0711 250.651 87.8498C250.66 87.7391 250.667 87.6526 250.672 87.5935C250.675 87.564 250.677 87.5413 250.678 87.5258L250.68 87.5081L250.68 87.5017C250.68 87.5016 250.68 87.5015 251.296 87.559L250.68 87.5017L250.683 87.4773L252.707 75.748L252.711 75.7322C253.32 73.0477 252.558 70.7957 250.797 68.5572C250.796 68.5571 250.796 68.557 250.796 68.5568Z" fill="#F7941D"/>
<path d="M161.407 170.67H187.843C190.723 170.67 193.059 173.01 193.059 175.896V215.089H156.148V175.938C156.148 173.028 158.502 170.67 161.407 170.67Z" fill="#6AD0CC"/>
<path d="M193.056 170.67H201.042C203.964 170.67 206.332 173.042 206.332 175.97V215.089H193.056V170.67Z" fill="#42B7AC"/>
<path d="M158.809 222.554C160.279 222.554 161.47 221.361 161.47 219.889C161.47 218.417 160.279 217.223 158.809 217.223C157.34 217.223 156.148 218.417 156.148 219.889C156.148 221.361 157.34 222.554 158.809 222.554Z" fill="#6AD0CC"/>
<path d="M190.394 222.554C191.864 222.554 193.056 221.361 193.056 219.889C193.056 218.417 191.864 217.223 190.394 217.223C188.925 217.223 187.733 218.417 187.733 219.889C187.733 221.361 188.925 222.554 190.394 222.554Z" fill="#6AD0CC"/>
<path d="M203.671 222.554C205.141 222.554 206.332 221.361 206.332 219.889C206.332 218.417 205.141 217.223 203.671 217.223C202.201 217.223 201.01 218.417 201.01 219.889C201.01 221.361 202.201 222.554 203.671 222.554Z" fill="#42B7AC"/>
<path d="M172.259 222.554C173.729 222.554 174.92 221.361 174.92 219.889C174.92 218.417 173.729 217.223 172.259 217.223C170.789 217.223 169.598 218.417 169.598 219.889C169.598 221.361 170.789 222.554 172.259 222.554Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M168.979 219.889C168.979 218.074 170.447 216.604 172.259 216.604V217.843C171.13 217.843 170.216 218.758 170.216 219.889C170.216 221.019 171.13 221.935 172.259 221.935V223.174C170.447 223.174 168.979 221.703 168.979 219.889Z" fill="#6AD0CC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M158.809 216.604C160.621 216.604 162.089 218.074 162.089 219.889C162.089 221.703 160.621 223.174 158.809 223.174V221.935C159.938 221.935 160.852 221.019 160.852 219.889C160.852 218.758 159.938 217.843 158.809 217.843V216.604Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M190.394 216.604C192.206 216.604 193.674 218.074 193.674 219.889C193.674 221.703 192.206 223.174 190.394 223.174V221.935C191.523 221.935 192.437 221.019 192.437 219.889C192.437 218.758 191.523 217.843 190.394 217.843V216.604Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M200.392 219.889C200.392 218.074 201.859 216.604 203.671 216.604V217.843C202.543 217.843 201.628 218.758 201.628 219.889C201.628 221.019 202.543 221.935 203.671 221.935V223.174C201.859 223.174 200.392 221.703 200.392 219.889Z" fill="#6AD0CC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M203.236 169.127V115.151H205.71V169.127H203.236Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M179.16 115.151V169.127H176.687V115.151H179.16Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M205.678 116.39H176.719V113.912H205.678V116.39Z" fill="#6AD0CC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M161.378 209.116V177.963H163.852V209.116H161.378Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M167.425 209.116V177.963H169.898V209.116H167.425Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M173.467 209.116V177.963H175.941V209.116H173.467Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M179.514 209.116V177.963H181.987V209.116H179.514Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M185.56 209.116V177.963H188.034V209.116H185.56Z" fill="#42B7AC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M238.451 62.9448L239.243 79.3391L234.301 79.5786L233.51 63.1843L238.451 62.9448Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M249.445 62.9443L250.533 85.3781L245.592 85.6187L244.503 63.1849L249.445 62.9443Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M245.692 58.3496C246.956 57.8315 248.401 58.4385 248.918 59.7052C250.746 64.1841 247.693 68.2676 243.826 69.1981C239.6 70.2151 235.035 67.7427 232.405 61.6233C231.864 60.3663 232.444 58.9085 233.698 58.3672C234.953 57.826 236.408 58.4062 236.949 59.6632C238.955 64.3307 241.509 64.6585 242.671 64.379C244.192 64.0128 244.796 62.703 244.338 61.5814C243.821 60.3146 244.427 58.8677 245.692 58.3496Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M259.456 149.504H273.619V150.743H259.456V149.504Z" fill="#FBB040"/>
<path d="M332.418 46.6172L346.006 47.2934C352.798 48.6528 353.964 55.1383 353.964 55.1383L363.123 92.3061C363.123 92.3061 359.038 109.936 349.857 89.1271C346.522 81.5654 338.447 61.1742 332.418 46.6172Z" fill="#6AD0CC"/>
<path d="M357.961 64.4313L366.233 67.8439L372.241 52.9011L363.968 49.4849L357.961 64.4313Z" fill="#FBB040"/>
<path d="M371.633 52.6564L365.624 67.6011L368.378 68.7121L374.386 53.7674L371.633 52.6564Z" fill="#F7941D"/>
<path d="M362.689 93.7256L370.789 73.1079L366.244 68.1553L358.879 78.8996C356.476 82.4078 356.575 87.0596 359.127 90.4616L362.689 93.7256Z" fill="#6AD0CC"/>
<path d="M367.799 61.663L363.053 61.2275L362.025 72.4958L370.753 73.2958L371.418 66.0173C371.615 63.8118 369.997 61.8648 367.799 61.663Z" fill="#FFFCF8"/>
<path d="M337.678 216.69L341.918 216.69L341.918 206.285L337.678 206.285L337.678 216.69Z" fill="#BCBEC0"/>
<path d="M323.002 216.69L327.242 216.69L327.242 206.285L323.002 206.285L323.002 216.69Z" fill="#BCBEC0"/>
<path d="M353.834 83.9448L335.55 81.0454L337.536 212.097V216.03H340.211C342.108 216.03 343.692 214.572 343.847 212.678L353.834 83.9448Z" fill="#0368AF"/>
<path d="M341.511 81.0455L327.298 216.027H325.287C322.517 216.027 320.255 213.811 320.195 211.035L317.333 81.042H341.511V81.0455Z" fill="#0368AF"/>
<path d="M351.13 121.679C351.13 121.679 355.215 94.342 352.628 67.9893C352.239 64.0385 347.267 61.1958 344.9 59.8116C334.228 53.5916 333.22 55.3475 323.499 56.3742C317.209 57.0362 312.374 63.0544 312.374 63.0544C312.374 63.0544 315.781 94.4836 316.65 106.354C316.795 108.34 317.636 110.205 319.015 111.625C333.132 126.193 351.13 121.679 351.13 121.679Z" fill="#FE6D54"/>
<path d="M353.487 115.264C353.487 115.264 355.95 86.3449 353.172 58.4664C352.755 54.2856 347.419 51.28 344.875 49.8179C333.418 43.2368 332.337 45.0954 321.902 46.1822C315.152 46.8832 309.961 53.2483 309.961 53.2483C309.961 53.2483 313.618 86.4972 314.551 99.054C314.707 101.157 315.611 103.129 317.089 104.63C332.242 120.036 353.487 115.264 353.487 115.264Z" fill="#6AD0CC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M313.445 98.1139L310.238 71.5613L312.694 71.2637L315.913 97.9206L315.915 97.9739L315.915 97.9745L315.915 97.9756L315.915 97.9774L315.915 97.9793C315.915 97.9836 315.916 97.9903 315.916 97.9993C315.916 98.0042 315.917 98.0099 315.917 98.0163C315.919 98.0521 315.923 98.11 315.929 98.1876C315.943 98.3429 315.968 98.5762 316.014 98.8691C316.106 99.4566 316.281 100.273 316.609 101.176C317.266 102.986 318.511 105.075 320.883 106.432L319.656 108.583C316.62 106.846 315.07 104.188 314.284 102.024C313.89 100.94 313.681 99.963 313.57 99.2538C313.514 98.8982 313.483 98.6073 313.465 98.4005C313.456 98.297 313.45 98.2144 313.447 98.155C313.446 98.1397 313.446 98.126 313.445 98.1139Z" fill="#88D9D6"/>
<path d="M332.948 45.1343C332.948 45.1343 314.505 45.7255 309.961 50.2781C307.176 53.0677 301.462 69.0974 297.458 76.4184C295.444 80.1001 298.109 84.5996 302.303 84.5996C309.491 84.5996 331.061 84.5996 331.061 84.5996V75.8944L316.453 72.3189L323.135 60.4347" fill="#6AD0CC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M319.672 129.128L321.181 199.021L318.707 199.075L317.198 129.182L319.672 129.128Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M336.941 129.146L337.842 188.556L336.606 188.575L335.705 129.165L336.941 129.146Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M336.708 198.44L336.606 190.847L337.842 190.83L337.945 198.424L336.708 198.44Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M354.105 115.289C354.105 115.29 354.105 115.29 353.487 115.264C352.869 115.238 352.869 115.238 352.869 115.238L352.872 115.157C352.874 115.103 352.878 115.023 352.882 114.917C352.89 114.706 352.903 114.393 352.918 113.987C352.949 113.174 352.993 111.99 353.044 110.502C353.145 107.524 353.273 103.331 353.382 98.4671C353.601 88.7371 353.743 76.3315 353.438 65.61L354.674 65.5747C354.98 76.3277 354.837 88.7578 354.619 98.495C354.509 103.365 354.381 107.563 354.28 110.544C354.229 112.034 354.185 113.22 354.154 114.034C354.139 114.441 354.126 114.755 354.118 114.967C354.113 115.073 354.11 115.154 354.108 115.208L354.105 115.289Z" fill="#42B7AC"/>
<path d="M338.115 40.7301L337.927 50.1575L327.739 45.881L329.499 40.6699L338.115 40.7301Z" fill="#FFFCF8"/>
<path d="M335.422 42.6596C335.422 42.6596 338.631 44.1464 340.193 44.596C341.295 44.9111 341.991 43.7252 343.041 40.932C343.157 40.6205 344.143 37.8556 344.592 36.5883C346.681 30.7046 342.164 28.9487 342.164 28.9487C336.888 26.1874 334.804 27.0441 334.804 27.0441L329.503 40.6736" fill="#FFFCF8"/>
<path d="M329.888 39.6717C329.888 39.6717 330.941 38.7088 331.065 37.76C331.121 37.3246 331.029 33.9331 333.651 35.2147C336.273 36.4997 337.044 32.379 339.627 33.4269C341.408 34.1491 344.504 33.1755 344.698 34.4217C344.896 35.6678 346.03 34.0429 346.705 32.4003C347.38 30.7576 346.25 28.8141 343.581 28.8283C340.913 28.8424 341.249 27.3768 339.21 26.906C338.687 26.7856 339.108 25.5926 338.56 25.5749C338.062 25.5607 337.32 25.8793 336.588 26.2652C335.857 26.6546 334.75 23.0118 333.743 26.8812C329.262 32.2516 329.888 39.6717 329.888 39.6717Z" fill="#FE6D54"/>
<path d="M339.507 210.355H344.073C344.073 210.355 344.437 218.144 351.384 218.144C355.964 218.144 357.423 222.87 356.575 222.87C352.805 222.87 335.811 222.87 335.811 222.87V214.058C335.807 212.012 337.464 210.355 339.507 210.355Z" fill="#FBB040"/>
<path d="M323.87 210.355H321.11C321.11 210.355 321.099 218.144 315.682 218.144C312.226 218.144 311.201 222.87 311.83 222.87C314.629 222.87 327.241 222.87 327.241 222.87V213.733C327.241 211.867 325.732 210.355 323.87 210.355Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M335.189 217.634C335.189 214.484 337.738 211.934 340.878 211.934H343.889C344.231 211.934 344.507 212.211 344.507 212.554C344.507 212.896 344.231 213.173 343.889 213.173H340.878C338.421 213.173 336.426 215.169 336.426 217.634C336.426 217.976 336.149 218.253 335.807 218.253C335.466 218.253 335.189 217.976 335.189 217.634Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M320.255 212.554C320.255 212.211 320.532 211.934 320.873 211.934H324.435C326.324 211.934 327.859 213.468 327.859 215.365V217.634C327.859 217.976 327.583 218.253 327.241 218.253C326.899 218.253 326.623 217.976 326.623 217.634V215.365C326.623 214.153 325.642 213.173 324.435 213.173H320.873C320.532 213.173 320.255 212.896 320.255 212.554Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M311.035 220.884C311.035 220.542 311.312 220.264 311.654 220.264H327.241C327.583 220.264 327.86 220.542 327.86 220.884C327.86 221.226 327.583 221.503 327.241 221.503H311.654C311.312 221.503 311.035 221.226 311.035 220.884Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M335.189 220.884C335.189 220.542 335.466 220.264 335.807 220.264H356.261C356.603 220.264 356.879 220.542 356.879 220.884C356.879 221.226 356.603 221.503 356.261 221.503H335.807C335.466 221.503 335.189 221.226 335.189 220.884Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M370.882 54.2729L373.716 55.3598L373.274 56.5169L370.44 55.4301L370.882 54.2729Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M369.617 57.1201L372.543 58.3061L372.08 59.4547L369.154 58.2687L369.617 57.1201Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M368.521 59.6953L371.511 60.8175L371.077 61.9777L368.087 60.8556L368.521 59.6953Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M318.612 216.395C318.612 216.053 318.889 215.775 319.23 215.775V216.395C319.23 215.775 319.23 215.775 319.231 215.775L319.231 215.775L319.233 215.775L319.238 215.775L319.253 215.776C319.265 215.776 319.282 215.776 319.302 215.777C319.343 215.778 319.401 215.781 319.473 215.786C319.617 215.795 319.82 215.815 320.064 215.853C320.551 215.929 321.214 216.082 321.913 216.389C323.327 217.012 324.887 218.275 325.401 220.761C325.471 221.096 325.256 221.424 324.921 221.494C324.587 221.563 324.26 221.348 324.19 221.013C323.775 219.007 322.552 218.024 321.416 217.524C320.839 217.27 320.285 217.141 319.874 217.077C319.669 217.045 319.502 217.029 319.389 217.022C319.333 217.018 319.29 217.016 319.263 217.015C319.249 217.015 319.239 217.015 319.234 217.014L319.229 217.014C319.229 217.014 319.229 217.014 319.229 217.014M318.612 216.395C318.612 216.737 318.888 217.014 319.229 217.014L318.612 216.395Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M347.059 216.395C347.059 216.737 346.783 217.014 346.442 217.014C346.441 217.014 346.441 217.014 346.44 217.014V216.395C346.44 217.014 346.441 217.014 346.441 217.014L346.441 217.014L346.437 217.014C346.431 217.015 346.422 217.015 346.408 217.015C346.381 217.016 346.338 217.018 346.281 217.022C346.168 217.029 346.002 217.045 345.797 217.077C345.385 217.141 344.831 217.27 344.255 217.524C343.118 218.024 341.895 219.007 341.48 221.013C341.411 221.348 341.084 221.563 340.749 221.494C340.415 221.424 340.2 221.096 340.269 220.761C340.783 218.275 342.344 217.012 343.757 216.389C344.456 216.082 345.119 215.929 345.606 215.853C345.851 215.815 346.053 215.795 346.197 215.786C346.269 215.781 346.327 215.778 346.368 215.777C346.389 215.776 346.405 215.776 346.417 215.776L346.432 215.775L346.437 215.775L346.439 215.775L346.44 215.775C346.44 215.775 346.44 215.775 346.44 216.395V215.775C346.782 215.775 347.059 216.053 347.059 216.395Z" fill="#F7941D"/>
<path fillRule="evenodd" clipRule="evenodd" d="M362.134 71.2583L370.862 72.0584L370.637 74.5261L361.908 73.726L362.134 71.2583Z" fill="#FE6D54"/>
<path fillRule="evenodd" clipRule="evenodd" d="M327.262 47.0238L328.217 44.7383L336.9 48.3826L337.149 46.3562L339.604 46.6592L338.955 51.9324L327.262 47.0238Z" fill="#FE6D54"/>
<path fillRule="evenodd" clipRule="evenodd" d="M351.366 115.36L351.362 115.361L351.344 115.366C351.326 115.37 351.299 115.376 351.262 115.384C351.187 115.4 351.074 115.424 350.923 115.452C350.621 115.509 350.17 115.583 349.583 115.653C348.411 115.793 346.699 115.914 344.56 115.833C340.284 115.673 334.303 114.708 327.517 111.479L326.986 112.599C333.944 115.909 340.094 116.905 344.514 117.071C346.723 117.154 348.5 117.03 349.729 116.883C350.174 116.83 350.547 116.774 350.844 116.724L350.513 121.637L351.747 121.721L352.132 116.003C352.145 115.806 352.064 115.614 351.912 115.487C351.761 115.36 351.558 115.313 351.366 115.36L351.366 115.36L351.366 115.36Z" fill="#EA3F28"/>
<path fillRule="evenodd" clipRule="evenodd" d="M330.362 79.308L318.105 45.5428L321.592 44.2725L334.073 78.6547L334.041 116.983L330.33 116.979L330.362 79.308Z" fill="#0368AF"/>
<path d="M302.299 84.5997H327.001L327.252 74.9599L316.452 72.3154" fill="#6AD0CC"/>
<path fillRule="evenodd" clipRule="evenodd" d="M315.914 72.011L321.313 62.4102L322.391 63.0184L316.991 72.6192L315.914 72.011Z" fill="#42B7AC"/>
<path d="M331.768 73.2852H327.001V84.5994H335.765V77.289C335.765 75.0765 333.977 73.2852 331.768 73.2852Z" fill="#FFFCF8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M328.238 73.2852V84.5994H325.764V73.2852H328.238Z" fill="#FE6D54"/>
<path fillRule="evenodd" clipRule="evenodd" d="M338.457 37.963C338.496 38.4032 338.642 38.4854 338.651 38.4889C338.659 38.4923 338.821 38.5355 339.155 38.2468C339.461 37.9823 339.786 37.5309 340.023 36.9459C340.261 36.3609 340.341 35.81 340.306 35.4068C340.268 34.9666 340.121 34.8844 340.113 34.8809C340.104 34.8775 339.942 34.8343 339.608 35.123C339.303 35.3875 338.977 35.8389 338.74 36.4239C338.503 37.0089 338.422 37.5598 338.457 37.963ZM337.225 38.071C337.17 37.4347 337.299 36.6861 337.594 35.9577C337.889 35.2294 338.318 34.6025 338.8 34.1851C339.255 33.7921 339.916 33.4635 340.578 33.733C341.241 34.0024 341.486 34.6996 341.538 35.2988C341.594 35.9351 341.465 36.6838 341.169 37.4121C340.874 38.1404 340.446 38.7673 339.963 39.1847C339.509 39.5778 338.848 39.9063 338.185 39.6368C337.523 39.3674 337.278 38.6702 337.225 38.071Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M342.648 39.525C342.687 39.9652 342.833 40.0474 342.841 40.0509C342.85 40.0543 343.012 40.0975 343.346 39.8088C343.652 39.5443 343.977 39.0929 344.214 38.5079C344.451 37.9229 344.532 37.372 344.497 36.9688C344.458 36.5286 344.312 36.4464 344.304 36.4429C344.295 36.4395 344.133 36.3963 343.799 36.685C343.493 36.9495 343.168 37.4009 342.931 37.9859C342.694 38.5709 342.613 39.1218 342.648 39.525ZM341.416 39.633C341.36 38.9967 341.49 38.2481 341.785 37.5197C342.08 36.7914 342.508 36.1645 342.991 35.7471C343.446 35.354 344.107 35.0255 344.769 35.295C345.431 35.5644 345.677 36.2616 345.729 36.8608C345.785 37.4971 345.655 38.2458 345.36 38.9741C345.065 39.7024 344.637 40.3293 344.154 40.7467C343.699 41.1398 343.038 41.4683 342.376 41.1988C341.714 40.9294 341.468 40.2322 341.416 39.633Z" fill="#FBB040"/>
<path fillRule="evenodd" clipRule="evenodd" d="M340.808 36.5869L342.568 37.1604L342.185 38.3387L340.426 37.7652L340.808 36.5869Z" fill="#FBB040"/>
<path d="M300.487 114.92H337.892V132.933C337.892 137.439 334.245 141.096 329.743 141.096H308.632C304.134 141.096 300.483 137.443 300.483 132.933V114.92H300.487Z" fill="#116097"/>
<path fillRule="evenodd" clipRule="evenodd" d="M307.944 84.7197V115.041H304.233V84.7197H307.944Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M305.438 127.336V132.82C305.438 134.641 306.91 136.116 308.728 136.116H329.651C331.469 136.116 332.941 134.641 332.941 132.82V129.156H334.178V132.82C334.178 135.325 332.152 137.355 329.651 137.355H308.728C306.227 137.355 304.201 135.325 304.201 132.82V127.336H305.438Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M331.383 127.955H304.819V126.716H331.383V127.955Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M336.21 114.92V120.11H300.487V118.871H334.973V114.92H336.21Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M308.106 123.622V119.491H311.816V123.622H308.106Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M325.884 123.622V119.491H329.595V123.622H325.884Z" fill="#0368AF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M308.258 51.5833C308.695 50.8521 309.121 50.2436 309.524 49.8398L310.398 50.716C310.105 51.0096 309.742 51.5123 309.319 52.2203C308.9 52.9198 308.441 53.7868 307.95 54.7838C306.968 56.778 305.873 59.2592 304.734 61.8948C304.39 62.6912 304.042 63.5019 303.692 64.3173C301.734 68.8776 299.714 73.5827 298.001 76.7159L298 76.716C296.213 79.9838 298.577 83.9799 302.303 83.9799H315.869V85.2189H302.303C297.64 85.2189 294.675 80.2161 296.916 76.1205C298.598 73.0444 300.589 68.4077 302.55 63.8419C302.901 63.0234 303.252 62.2073 303.599 61.4027C304.738 58.7672 305.844 56.2604 306.841 54.2355C307.34 53.2231 307.815 52.323 308.258 51.5833Z" fill="#88D9D6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M325.775 85.2191H320.265V83.98H325.775V85.2191Z" fill="#88D9D6"/>
<path fillRule="evenodd" clipRule="evenodd" d="M132.829 197.406V148.417H134.065V197.406H132.829Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M216.156 197.405V119.491H217.393V197.405H216.156Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M291.143 197.405V119.491H292.38V197.405H291.143Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M365.767 197.405V119.491H367.003V197.405H365.767Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M35.2433 219.108L53.439 200.879L54.3135 201.756L36.1179 219.984L35.2433 219.108Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M44.3394 219.108L62.5386 200.879L63.4131 201.756L45.2139 219.984L44.3394 219.108Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M53.439 219.108L71.6381 200.879L72.5126 201.756L54.3135 219.984L53.439 219.108Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M263.655 219.108L281.85 200.879L282.725 201.756L264.529 219.984L263.655 219.108Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M272.751 219.108L290.95 200.879L291.824 201.756L273.625 219.984L272.751 219.108Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M281.851 219.108L300.049 200.879L300.924 201.756L282.725 219.984L281.851 219.108Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M329.464 1.57645H258.427V0.337402H329.464V1.57645Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M259.046 0.957031V15.4114H257.809V0.957031H259.046Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M134.065 131.262V145.238H132.829V131.262H134.065Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M132.829 127.028V119.176H134.065V127.028H132.829Z" fill="#CAE6F9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M89.0482 1.57645C60.1051 1.57645 36.6383 25.0817 36.6383 54.0801V115.264H35.4014V54.0801C35.4014 24.3973 59.422 0.337402 89.0482 0.337402V1.57645Z" fill="#CAE6F9"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="429" height="223.157" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}
export default MainRegImg;