// Стили для кастомного тэга select

/* eslint-disable no-dupe-keys */
const customStyles = {
  option: () => {
    const boxSizing = "border-box"
    const display = "block"
    const fontSize = "inherit"
    const label = "option"
    const padding = "8px 12px"
    const userSelect = "none"
    const width = "100%"
    const backgroundColor = 'white';
    const color = '#828282';
    const cursor = "pointer";
    return { backgroundColor, cursor, color, boxSizing, display, fontSize, label, padding, userSelect, width };
  },
  control: () => ({
    webkitAlignItems: "center",
    webkitBoxAlign: "center",
    msFlexAlign: "center",
    alignItems: "center",
    cursor: "default",
    display: "-webkit-box",
    display: "-webkit-flex",
    display: "-ms-flexbox",
    display: "flex",
    webkitFlexWrap: "wrap",
    msFlexWrap: "wrap",
    flexWrap: "wrap",
    webkitBoxPack: "justify",
    webkitJustifyContent: "space-between",
    msFlexPack: "justify",
    justifyContent: "space-between",
    position: "relative",
    boxSizing: "border-box"
  })
};

export default customStyles;