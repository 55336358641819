// Универсальный класс для запросов к апи

import { showErrorMessage } from "../scripts/functions"
import history from "../scripts/history"
import {WELCOMEAPI} from "../scripts/indexphp"

export default class ApiService {
  constructor() {
    this._apiBase = WELCOMEAPI
  }

  emaildomain() {
    let d=window.location.host
    if (!d.match(/\.(sereniti\.ru|travel)$/)) {
      d='localhost';
    }
    return d
  }


  async getResource(url,method,data, fullResponse, externalUrl)  {
    let res;

    const headers =  externalUrl ? {
      'Content-type': 'application/json',
    } : {
      'Content-type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }

    const collectedUrl = externalUrl ? url : this._apiBase + url;

    if(method === 'GET' || method === 'get') {
      res = await fetch(`${collectedUrl}`, {
        method: method,
        headers: headers
      });
    } else {
      res = await fetch(`${collectedUrl}`, {
        method: method,
        body: JSON.stringify(data),
        headers: headers
      });
    }

    if(res.status === 401) {
      history.push('/pre-registration')
    } 
    
    if(res.status === 204) {
      return res
    }

    if(!res.ok) {
      showErrorMessage(res);
      return res.json();
    }

    return await res.json()
    .then((reponse) => {
      if(fullResponse || res.status === 401) return {...reponse, requestStatus: res.status, ok: res.ok}
      else return reponse
    })
  }
}