import React, { Component } from "react";
import { Link } from "react-router-dom";
import IMask from 'imask';
import MyInput from "../../../utils/input";

import { ValidFuncConsumer } from "../../../scripts/validFuncContext";
import ApiService from "../../../services/apiService";

import { setLoadStatus, addMessage, scrollPageTo } from "../../../scripts/functions";
import { connect } from "react-redux";
import history from "../../../scripts/history";

class RegStepSecond extends Component {

  state = {
    type: '',
    name: '',
    phoneNumber: localStorage.getItem('phoneNumber'),
    email: '',
    birthDay: '',
    birthPlace: '',
    passportNumber: '',
    passportDate: '',
    passportCode: '',
    passportIssue: '',
    address: '',
    bik: '',
    bankName: '',
    settlementAccount: '',
    correspondentAccount: '',
    bikIsCorrect: false,
    changed: false,
    toCreate: true
  }

  _mounted = false
  apiService = new ApiService();

  async componentDidMount() {
    await setLoadStatus(this.props.load, true);
    this._mounted = true;
    this.props.updateCurStep(1);

    await this.getCompanyInfo()

    if (this.props.state.doneStep >= 2) {
      // await this.getBankInfo();
      await this.getUserInfo()
    }

    await setLoadStatus(this.props.load, false);
  };

  componentWillUnmount() {
    this._mounted = false
  }

  getCompanyInfo = async () => {
    return await this.apiService.getResource(`api/companyInfo?companyId=${localStorage.getItem('companyId')}`, "GET", {})
      .then(async (res) => {
        if (!res || !res.success) return false
        this._mounted && this.setMask();
        return false
      })
  }

  getUserInfo = async () => {
    return await this.apiService.getResource('api/employee/getEmployees', 'POST', { companyId: localStorage.getItem('companyId') })
      .then(async (res) => {
        // console.log(res)
        if (!res || !res.data.length) return false
        let userId = res.data.filter(u => u.role === 'CEO')[0];
        if (userId) userId = userId.userId
        return userId ? await this.apiService.getResource(`api/employee?userId=${userId}`, 'GET', {}) : false
      })
      .then(async (result) => {
        // console.log(result)
        if (!result || !result.success || !result.data) return false

        this._mounted && await this.setState({
          ...result.data,
          userId: result.data.userId,
          toCreate: false
        })

        this._mounted && this.updateMasks()

        return true
      })
  }

  setMask = () => {
    this.birthDay = IMask(document.getElementById('birthDay'), { mask: '00.00.0000', lazy: true });
    this.passportNumber = IMask(document.getElementById('passportNumber'), { mask: '0000 000000', lazy: true });
    this.passportDate = IMask(document.getElementById('passportDate'), { mask: '00.00.0000', lazy: true });
    this.passportCode = IMask(document.getElementById('passportCode'), { mask: '000-000', lazy: true });
  };

  updateMasks = () => {
    this.birthDay.updateValue()
    this.passportNumber.updateValue()
    this.passportDate.updateValue()
    this.passportCode.updateValue()
  }

  onSubmit = (e) => {
    e.preventDefault();
  };

  toTheNextStep = async (checkValidity) => {
    if (this.props.state.load) {
      return false
    }

    if (!(await this.checkCeoData())) return false

    if (!this.state.changed && !this.state.toCreate) {
      this.nextStep()
      return false
    }
    await setLoadStatus(this.props.load, true);

    const b = await this.sendСeoData(checkValidity);
    if (!b) return false

    await this.apiService.getResource('api/registration/skipStage', "POST", { companyId: localStorage.getItem('companyId'), stage: 4 })
    this.nextStep()
    return true
  }

  nextStep = () => {
    this.props.updateDoneStep(2);
    history.push(`/registration/step-${3}`)
  }

  sendBankData = async (checkValidity) => {
    if (checkValidity('reg-step-second-form-bank')) {
      return false
    }
    return await this.apiService.getResource('api/bankRequisite', this.state.toCreate ? "POST" : 'PUT', {
      companyId: localStorage.getItem('companyId'),
      bik: this.state.bikIsCorrect,
      correspondentAccount: this.state.correspondentAccount,
      settlementAccount: this.state.settlementAccount,
    })
      .then((res) => {
        if (res && !res.success) {
          addMessage(`#${res.errorField}`, res.message, true)
          scrollPageTo(document.querySelector(`#${res.errorField}`))
          return false
        }
        return res && res.success
      })
  }

  checkCeoData = async () => {
    const data = {
      role: 2,
      companyId: localStorage.getItem('companyId'),
      name: this.state.name,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email,
      birthDay: this.state.birthDay,
      birthPlace: this.state.birthPlace,
      passportNumber: this.state.passportNumber,
      passportDate: this.state.passportDate,
      passportCode: this.state.passportCode,
      passportIssue: this.state.passportIssue,
      address: this.state.address,
    };

    return data
  }

  sendСeoData = async (checkValidity) => {
    if (checkValidity('reg-step-second-form-seo')) {
      return false
    }

    const data = await this.checkCeoData();

    return await this.apiService.getResource('api/employee', this.state.toCreate ? 'POST' : 'PUT', { ...data, userId: this.state.userId })
      .then(async (res) => {
        if (res && !res.success) {
          addMessage(`#${res.errorField}`, res.message, true)
          scrollPageTo(document.querySelector(`#${res.errorField}`))
          return false
        }
        return res && res.success
      })
  }

  render() {
    const {  name, phoneNumber, email, birthDay, birthPlace, passportNumber, passportDate, passportCode, passportIssue, address } = this.state;

    return (
      <ValidFuncConsumer>
        {({ checkValidity }) => {
          return (
            <>
              <form id="reg-step-second-form-seo" className="reg-step-form" onSubmit={this.onSubmit}>
                <h2>Данные руководителя</h2>
                <div className="row-of-inputs reverse-inputs-length without-max-width">
                  <MyInput title="ФИО" value={name} onInput={(e) => this._mounted && this.setState({ name: e.target.value, changed: true })} disabled={!this.props.state.canEdit} readOnly={false} required />
                  <MyInput title="Телефон" value={phoneNumber} disabled readOnly required />
                </div>

                <div className="row-of-inputs reverse-inputs-length without-max-width">
                  <MyInput title="Email" value={email} placeholder={'mailbox@domain.ru'} pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" onInput={(e) => this._mounted && this.setState({ email: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                </div>

                <h3>Паспортные данные</h3>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Дата рождения" id="birthDay" minLength="10" maxLength="12" pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" placeholder="XX.XX.XXXX" required value={birthDay} onInput={(e) => this._mounted && this.setState({ birthDay: e.target.value, changed: true })} disabled={!this.props.state.canEdit} messagetext="Введите в формате XX.XX.XXXX" />
                  <MyInput title="Место рождения" id="birthPlace" placeholder="Точно как в паспорте" value={birthPlace} onInput={(e) => this._mounted && this.setState({ birthPlace: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                </div>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Серия и номер паспорта" id="passportNumber" pattern="^\d{4} \d{6}$" placeholder="XXXX XXXXXX" messagetext="В формате XXXX XXXXXX" value={passportNumber} onInput={(e) => this._mounted && this.setState({ passportNumber: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                  <MyInput title="Дата выдачи паспорта" id="passportDate" pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" placeholder="XX.XX.XXXX" messagetext="Введите в формате XX.XX.XXXX" value={passportDate} onInput={(e) => this._mounted && this.setState({ passportDate: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                  <MyInput title="Код подразделения" id="passportCode" pattern="^\d{3}-\d{3}$" placeholder="XXX-XXX" messagetext="Введите в формате XXX-XXX" value={passportCode} onInput={(e) => this._mounted && this.setState({ passportCode: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                </div>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Кем выдан паспорт" id="passportIssue" placeholder="Точно как в паспорте" value={passportIssue} onInput={(e) => this._mounted && this.setState({ passportIssue: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                </div>

                <div className="row-of-inputs without-max-width">
                  <MyInput title="Адрес проживания" id="address" placeholder="Город, адрес" value={address} onInput={(e) => this._mounted && this.setState({ address: e.target.value, changed: true })} disabled={!this.props.state.canEdit} required />
                </div>

                <div className="btns-container">
                  <Link to={`/registration/step-1`} className="secondary-btn app-btn">Назад</Link>
                  <button type="button" id="app-btn" className="primary-btn app-btn" onClick={() =>
                    this.props.state.canEdit ?
                      this.toTheNextStep(checkValidity).then(() => setLoadStatus(this.props.load, false))
                      : this.nextStep()
                  }>Продолжить</button>
                </div>
              </form>
            </>
          )
        }}
      </ValidFuncConsumer>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: (bool) => {
      return dispatch({ type: 'LOAD_CHANGE', payload: bool })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegStepSecond);
