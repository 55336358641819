import React, { forwardRef } from 'react';
import { ValidFuncConsumer } from '../scripts/validFuncContext';

const MyInput = (props, ref) => {
  let {
    name, 
    title, 
    id = null,
    myClassName = '', 
    myInputClassName = '', 
    value,
    defaultValue, 
    required = true, 
    pattern = null,
    withFocus = true, 
    withBlur = true, 
    withBind = true,
    onChange,
    onInput,
    onFocus,
    onBlur,
    onKeyUp,
    messagetext = 'Некорректный ввод',
    placeholder = false,
    disabled = false,
    list,
    type,
    autocomplete,
    readOnly = false,
    style,
    ...rest
  } = props;

  return (
    <ValidFuncConsumer>
      {({showInvalid, showValid, bindClickSubmit}) => 
        <label className={`app-label ${myClassName}`} style={style}>
          <div className="input-title">{title}</div>
          <input 
            className={myInputClassName}
            ref={ref} 
            id={id}
            type={type ? type : 'text'}
            disabled={disabled}
            placeholder={placeholder ? placeholder : undefined}
            name={name} 
            value={value !== undefined ? value : undefined}
            defaultValue={defaultValue}
            required={required ? required : undefined} 
            pattern={pattern} 
            maxLength="256" 
            onFocus={(e) => {
              if(withFocus) showValid(e.target);
              if(onFocus) onFocus(e);
            }} 
            onBlur={(e) => {
              if(withBlur) showInvalid(e.target);
              if(onBlur) onBlur(e);
            }} 
            onKeyUp={(e) => {
              if(withBind) bindClickSubmit(e, withBind);
              if(onKeyUp) onKeyUp(e);
            }} 
            onChange={onChange}
            onInput={onInput}
            list={list ? list : undefined}
            messagetext={messagetext}
            readOnly={readOnly}
            {...rest}
          />
        </label>
      }
    </ValidFuncConsumer>
  );
}

export default forwardRef(MyInput);