import React from 'react';

const Checkbox = props => {
  const {
    handler,
    count,
    index,
    text,
    multiple,
    description,
    isCheckbox,
    disabled,
  } = props;

  const active = multiple ? count[index] : count === index;
  const classNameWrapper = `${active ? 'active' : ''} ${disabled ? 'label-disabled' : ''} app-check-label-wrapper`
  const className = `${active ? 'active' : ''} ${isCheckbox ? 'app-checkbox' : 'app-radio'}`

  return (
    <div className={classNameWrapper} onClick={() => handler(index)}>
      <div className={className}><div></div></div>
      <div className={'app-radio-container'}>
        <div className="title-div">{text}</div>
        {description ? <div>{description}</div> : ''}
      </div>
    </div>
  )
};

const CheckboxList = props => {
  const {
    className,
    text = [],
    description = [],
    show = () => true,
  } = props;

  return (
    <div className={className}>
      {text.map((t, i) => (typeof show === 'function' ? show(i) : show) ? <Checkbox {...props} key={i} text={t} index={i} description={description[i]} /> : null)}
    </div>
  );
};

export default CheckboxList;