import React from 'react';

const  {
  Provider : ValidFuncProvider,
  Consumer : ValidFuncConsumer
} = React.createContext();

export {
  ValidFuncProvider,
  ValidFuncConsumer 
};